import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';
import { combineLatest } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  ITextFilterParams,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";

import {
  AgChartOptions,
} from "ag-charts-community";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

import {
  faTimes,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { CustomNoRowsOverlayComponent } from '../../parts/custom-no-rows-overlay/custom-no-rows-overlay.component';

@Component({
  selector: 'app-readquest-analytics-drill-down-single-user-skill',
  templateUrl: './readquest-analytics-drill-down-single-user-skill.component.html',
  styleUrls: ['./readquest-analytics-drill-down-single-user-skill.component.scss']
})
export class ReadquestAnalyticsDrillDownSingleUserSkillComponent implements OnInit {
  //incoming
  public teamID: number;
  public title: string;
  public metricsUserID: number;
  public user: any;
  public skill: any;
  public skillSheetsID: any;
  public clientCode: string;
  private targetURL: string;
  private legacy: boolean = false;
  public backRoute: string[];
  public phonemeAccuracyRoute: string[];
  public wordAccuracyRoute: string[];
  public backToolTip: string = "Back to RQ Analytics";
  public origin: string;
  private gridApis: GridApi[] = [];

  //persistent
  private token: string;
  public skillData: any;

  //data
  public queryParams = {
    is_teacher: false,
    page_number: '1',
    page_size: '25',
    start: '',
    end: '',
    tmz: 'UTC'
  };

  //icons
  public faTimes = faTimes;
  public faDownload = faDownload;

  //visibility
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //calendar config
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      skipHeader: false
    };
  public defaultColDef: ColDef = {
    flex: 1,
    filter: true,
    minWidth: 50,
    maxWidth: 400,
    wrapText: true,
    autoHeight: true,
  };
  public autoGroupColumnDef: any = {
    minWidth: 250,
    pinned: 'left',
  };
  public rowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";
  public noRowsOverlayComponentParams: any = {
    noRowsMessageFunc: () =>
      "No instruction attempts for this skill attempt.",
  };

  //date/time
  public tz: any;
  public tz_iana: any;

  //charts
  public durationChartOptions: AgChartOptions;

  //options
  dropdownOptions = [{
    "label": "Teacher",
    "value": "teacher"
  }, {
    "label": "Student",
    "value": "student"
  }
  ]
  public userTypeSelector: UntypedFormControl = new UntypedFormControl("teacher");
  CustomNoRowsOverlayComponent = CustomNoRowsOverlayComponent;


  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private route: ActivatedRoute,
    private _sharedDataService: SharedDataService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveTeamID();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;
    this.queryParams.tmz = this.tz_iana;

    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.metricsUserID = +params.get('user_id');
      this.skillSheetsID = params.get('skill_id');
      this.legacy = queryParams.get('legacy_state') === "true" ? true : false;

      this.targetURL = `v1/foretell/display/rqu/team/${this.teamID}/user-data/${this.metricsUserID}/skill/${this.skillSheetsID}`;

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.is_teacher = retrievedParams.is_teacher;
      this.userTypeSelector.setValue(retrievedParams.userType);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.model = new chartDates(
        moment(retrievedParams.start).format("MMM D, YYYY"),
        moment(retrievedParams.end).format("MMM D, YYYY")
      );

      this.phonemeAccuracyRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'readquest',
        'user',
        this.metricsUserID.toString(),
        'skill',
        this.skillSheetsID,
        'phoneme-accuracy',
      ];

      this.wordAccuracyRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'readquest',
        'user',
        this.metricsUserID.toString(),
        'skill',
        this.skillSheetsID,
        'word-accuracy',
      ];

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'readquest',
        'user',
        this.metricsUserID.toString(),
      ];

      this.origin = queryParams.get('origin') !== undefined ? queryParams.get('origin') : "user";

      if (this.origin === "skill") {
        this.backRoute = [
          '/client',
          this.clientCode,
          'analytics',
          'readquest',
          'skill',
          this.skillSheetsID,
        ];
      }

      this.buildColumnDefs();
      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private async retrieveData() {

    let skillData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    let userData = await this.retrieveUserData(skillData.user).catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.user = userData.user;

    this.processGridData(skillData);

    this.gridLoading = false;

    //send correct label info
    let updatePageTitle = {
      type: "pageTitleChange",
      data: {
        pageTitle: `RQ Analytics: ${this.skill.skill_name} Attempts for ${this.user.first_name} ${this.user.last_name} (${this.user.username})`,
      },
    };

    this._sharedDataService.sendSharedData(updatePageTitle);

    this.backToolTip = `Back to RQ User Analytics: ${this.user.first_name} ${this.user.last_name} (${this.user.username})`;

    if (this.origin === "skill") this.backToolTip = `Back to RQ Skill Analytics: ${this.skill.skill_name}`;
  }

  private retrieveUserData(metricsUser) {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(`/user/${metricsUser.external_user_id}`, "get", {}, getOptions).toPromise();
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      queryParams: this.queryParams
    }

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  private processGridData(skillData) {
    this.skill = skillData.skill;

    let duration = this.skill.total_duration;
    let durationInHours = Math.floor(duration / 3600);
    let durationInMinutes = Math.floor(duration / 60);

    if (durationInHours > 0) {
      this.skill.duration = `${durationInHours}h ${durationInMinutes}m`;
    } else {
      this.skill.duration = `${durationInMinutes}m`;
    }

    this.skill.skill_name = this.skill.skill_name.toLowerCase().indexOf("skill") === 0 ? this.skill.skill_name : `Skill: ${this.skill.skill_name}`;

    this.skillData = skillData.skill_attempts.map((attempt) => {

      //if end_time and start_time have the same month, day, and year, concatenate the dates to EEE, dd LLL yyyy h:mm a  - h:mm a Z
      let start = moment(attempt.start_time).format("MMM D, YYYY");
      let end = moment(attempt.end_time).format("MMM D, YYYY");

      if (start === end) {
        attempt.start_time = this._metricsServicesService.timeFormatter(attempt.start_time, "EEE, dd LLL yyyy h:mm a");
        attempt.end_time = this._metricsServicesService.timeFormatter(attempt.end_time, "h:mm a z");
      } else {
        attempt.start_time = this._metricsServicesService.timeFormatter(attempt.start_time);
        attempt.end_time = this._metricsServicesService.timeFormatter(attempt.end_time);
      }

      attempt.duration = this._metricsServicesService.convertDurationToTime(attempt.duration);

      return attempt;
    });
  }

  private buildColumnDefs() {

    let columnDefs = [
      {
        headerName: "Instruction #",
        field: "action_number",
        type: "number",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Action",
        field: "action_type",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Attempt #",
        field: "action_attempt_number",
        type: "number",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Said",
        field: "system_said_before",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Skipped?",
        field: "skip_type",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Expected Verbal",
        field: "expected_verbal_response",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "User Verbal Response",
        field: "captured_verbal_response",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Response",
        field: "verbal_evaluation",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Expected Gesture",
        field: "expected_gesture",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "User Gesture Response",
        field: "captured_gesture",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Response",
        field: "gesture_evaluation",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Evaluation",
        field: "total_evaluation",
        type: "text", filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
    ]

    if (this.legacy) columnDefs = [
      {
        headerName: "Action",
        field: "action",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Said",
        field: "system_said",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Expected Verbal",
        field: "expected_verbal",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Expected Gesture",
        field: "expected_gesture",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "User Verbal Response",
        field: "user_verbal",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Response",
        field: "verbal_evaluation",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "User Gesture Response",
        field: "user_gesture",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "System Response",
        field: "gesture_evaluation",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: "Evaluation",
        field: "total_evaluation",
        type: "text",
        filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
    ];

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columnDefs);
  }

  public async setupDateChart() {
    this.gridLoading = true;
    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let userType = this.userTypeSelector.value;

    this.queryParams.start = startDate;
    this.queryParams.end = endDate;
    this.queryParams.is_teacher = userType === "teacher" ? true : false;

    // Prepare the query parameters string
    const queryParams = `start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}&user_type=${encodeURIComponent(userType)}&tmz=${encodeURIComponent(this.tz_iana)}`;

    // Update the URL without navigating
    const path = this.location.path();
    const pathWithoutParams = path.split('?')[0];
    this.location.replaceState(`${pathWithoutParams}?${queryParams}`);

    let skillData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.processGridData(skillData);
    this.gridLoading = false;
  }

  public onDownloadCSV(index) {
    this.gridApis[index].exportDataAsCsv(this.getParams(index));
  }

  public onGridReady(params: GridReadyEvent, index) {
    this.gridApis[index] = params.api;
  }

  private getParams(index) {

    let start_date = moment(this.queryParams.start).format("YYYY-MM-DD");
    let end_date = moment(this.queryParams.end).format("YYYY-MM-DD");
    let pulled = moment().format("YYYY-MM-DD");

    return {
      fileName: `RQ_Skill_Attempt_num_${index + 1}_${this.user.username}_${this.skill.skill_name}_${start_date}_${end_date}_pulled_${pulled}.csv`,
    };
  }
}
