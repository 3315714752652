import { Component } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { combineLatest } from 'rxjs';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';

@Component({
  selector: 'app-metrics-aia-by-user',
  templateUrl: './metrics-aia-by-user.component.html',
  styleUrls: ['./metrics-aia-by-user.component.scss'],
  providers: [TitleCasePipe]
})
export class MetricsAiaByUserComponent {
  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public title: string = "AIA Reporting Dashboard";
  public users: any;
  private targetURL: string;
  public userID: number;
  public user: any;
  public userInfo: string = "";

  //nav related
  public backRoute: string[];

  //icons
  public faTimes = faTimes;

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _sharedDataService: SharedDataService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveClientCode();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;

    this.buildColumnDefs();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.userID = +params.get('user_id');

      this.targetURL = `foretell/display/aia/sessions/${this.teamID}/user/${this.userID}`;

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'aia'
      ];

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'Scenario', field: 'event_name', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Start', field: 'start_time', type: 'date'
      },
      {
        headerName: 'End', field: 'end_time', type: 'date'
      },
      {
        headerName: '# of Practice Sessions', field: 'practice_session_count', type: 'number', filter: 'agNumberColumnFilter', filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Submitted Files', field: 'submitted_files', type: "boolean", cellStyle: { 'text-decoration': 'underline', 'cursor': 'pointer' }
      }
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  private async retrieveData() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let userData = await this.retrieveUser(getOptions).catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.user = userData.user;
    this.userInfo = `${this.user.first_name} ${this.user.last_name} (${this.user.username})`;

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.rowData = gridData.asset_sessions;

    console.log("this.rowData", this.rowData);

    this.gridLoading = false;
  }

  private retrieveUser(getOptions) {
    //first retrieve user meta
    let retrieveUser = this._xrPlatformRestService.retrieveEntityData(
      "user",
      this.userID,
      getOptions
    );

    return retrieveUser.toPromise();
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {}

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  public onCellClicked(event) {

    let thisColumn = event.colDef.field;
    console.log("event on cell clicked", event);

    this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'session', event.data['session_id']], {});

  }
}
