<div class="interactive-analytics-card">
  <div class="row justify-content-start flex-row metrics-controls">
    <div
      class="col-10 offset-2 d-flex flex-nowrap justify-content-end align-items-center"
    >
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.startTime.singular"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
          [label]="startDateLabel"
          (dateChanged)="checkDateRange($event, 'start')"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="myDatePickerOptions"
          [placeholder]="'Selected ' + labels.endTime.singular"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
          [label]="endDateLable"
          (dateChanged)="checkDateRange($event, 'end')"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="timeDenomination"
          [formControl]="timeSelector"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="setupDateChart()"
      >
        Update Chart
      </button>
    </div>
  </div>
  <div class="row d-flex flex-nowrap">
    <div
      class="col-2 d-flex justify-content-center flex-row metrics-aggregates"
    >
      <div class="aggregate-container w-100">
        <h3 class="text-center">Aggregate Values</h3>
        <p *ngIf="metricsLoadingDC" class="mb-0 loading-text mt-0">
          Loading table...
        </p>
        <table *ngIf="!metricsLoadingDC" mdbTable striped="true">
          <tbody>
            <tr *ngFor="let aggregate of dateAggregates">
              <td class="aggregate-value semi-bold">{{ aggregate.label }}</td>
              <td class="aggregate-label">{{ aggregate.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-10">
      <h3 class="text-center">Sessions over time</h3>
      <p *ngIf="metricsLoadingDC" class="mb-0 loading-text mt-0">
        Loading chart...
      </p>
      <div
        *ngIf="!metricsLoadingDC"
        class="chart-container"
        style="position: relative; height: 42vh; width: auto"
      >
        <canvas #sessionsChart> </canvas>
      </div>
    </div>
  </div>
  <div class="interactive-meetings-card content-card">
    <div class="row" *ngIf="!sessionsLoaded">
      <div class="col-12">
        <p class="mb-0 loading-text">Loading sessions table...</p>
      </div>
    </div>
    <div *ngIf="sessionsLoaded">
      <div class="row">
        <div class="col-12">
          <h4 class="text-center meeting-header mb-0">Sessions</h4>
        </div>
      </div>
      <div class="row" *ngIf="allSessions.length">
        <div class="col-4 meeting-search">
          <div class="md-form w-100 mr-2">
            <input
              type="search"
              [(ngModel)]="searchText"
              (ngModelChange)="onSearchTextChange($event)"
              class="form-control"
              id="searchMeetings"
              mdbInput
            />
            <label for="searchMeetings">Search Sessions</label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="(sessions | filter : searchText).length < 1">
        <div class="col-12">
          <div class="loading-text mt-0">
            No Sessions Found for this
            {{ !sessions.length ? "date range" : "search" }}
          </div>
        </div>
      </div>
      <div class="row" *ngIf="(sessions | filter : searchText).length > 0">
        <div class="col-12 d-flex meeting-table">
          <table
            class="table table-responsive-md btn-table meeting-nav-table"
            mdbTable
            small="true"
            mdbTableScroll
            scrollY="true"
            #tableEl="mdbTable"
          >
            <col style="width: 35%" />
            <col style="width: 12%" />
            <col style="width: 12%" />
            <col style="width: auto" />
            <col style="width: auto" />
            <col style="width: 9%" />
            <thead class="sticky-top theme-base-medium-dark white-text">
              <th *ngFor="let head of headElements; let i = index" scope="col">
                {{ head | titlecase }}
                <mdb-icon
                  fas
                  *ngIf="sortBy !== sort_elements[i]"
                  icon="sort"
                  class="sort-icon"
                  [mdbTooltip]="'Sort by ' + head"
                  placement="bottom"
                  (click)="sortTable(sort_elements[i], 'desc')"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == sort_elements[i] && sortOrder == 'asc'"
                  (click)="sortTable(sort_elements[i], 'desc')"
                  fas
                  icon="caret-up"
                ></mdb-icon>
                <mdb-icon
                  *ngIf="sortBy == sort_elements[i] && sortOrder == 'desc'"
                  (click)="sortTable(sort_elements[i], 'asc')"
                  fas
                  icon="caret-down"
                ></mdb-icon>
              </th>
            </thead>
            <tbody #row>
              <ng-container
                *ngFor="let el of sessions; let i = index"
              >
                <tr
                  [routerLink]="[
                    '/client',
                    clientCode,
                    'analytics',
                    el.session_id
                  ]"
                  [queryParams]="{
                    breakdownType: breakdownType,
                    start_date: start,
                    end_date: end
                  }"
                  mdbTableCol
                  mdbTooltip="{{ el.session_name }}"
                  placement="top"
                >
                  <td class="session-name-container">
                    {{ el.session_name }}
                  </td>
                  <td class="text-center">
                    {{ el.distinct_users }}
                  </td>
                  <td class="text-center">
                    {{ el.total_connections }}
                  </td>
                  <td>
                    {{ el.start_time | amDateFormat : "MMM D, YYYY" }} &#64;
                    {{ el.start_time | amDateFormat : "h:mm a" }}
                  </td>
                  <td
                    *ngIf="
                      el.duration.toLowerCase().indexOf('in progress') === -1
                    "
                  >
                    {{ el.end_time | amDateFormat : "MMM D, YYYY" }} &#64;
                    {{ el.end_time | amDateFormat : "h:mm a" }}
                  </td>
                  <td
                    *ngIf="
                      el.duration.toLowerCase().indexOf('in progress') !== -1
                    "
                  >
                    NA
                  </td>
                  <td class="text-center">
                    {{ el.duration }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot class="grey lighten-5 w-100" *ngIf="(sessions | filter : searchText).length > 0">
              <tr>
                <td colspan="6">
                  <p-paginator
                    #pagination
                    (onPageChange)="onPageChange($event)"
                    [first]="first"
                    [rows]="rows"
                    [totalRecords]="allSessions.length"
                    [showFirstLastIcon]="true"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} - {last} of {totalRecords}"
                  ></p-paginator>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
