<div class="forgot-password-wrapper">
  <mdb-card>
    <mdb-card-header class="theme-base-medium-dark white-text text-center py-4">
      <h5><strong>Request a Password Reset</strong></h5>
    </mdb-card-header>
    <mdb-card-body>
      <form
        [formGroup]="forgotPasswordForm"
        *ngIf="!formLoading"
        autocomplete="disabled"
      >
        <div class="row p-0">
          <div class="col-12">
            <p>
              <span class="action-msg-small">Required*</span>
            </p>
          </div>
        </div>
        <div class="md-form">
          <input
            type="text"
            id="email"
            class="form-control"
            formControlName="email"
            mdbInput
            mdbValidate
          />
          <label for="email">* Email</label>
          <mdb-error
            *ngIf="
              forgotPasswordForm.controls.email.invalid &&
              (forgotPasswordForm.controls.email.dirty ||
                forgotPasswordForm.controls.email.touched)
            "
          >
            Please enter a valid email address
          </mdb-error>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="forgotPasswordForm.invalid"
            (click)="submitForm()"
          >
            Request Password Reset
          </button>
        </div>
      </form></mdb-card-body
    ></mdb-card
  >
</div>
