import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { turnState } from "ng-uikit-pro-standard";

//third-party services
import { CoolLocalStorage } from "@angular-cool/storage";
import { SettingsService } from "src/app/services/utilities/settings.service";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { EventMediaVersion3Service } from "src/app/modules/event-management/services/event.media.version.3.service";
import { Location } from "@angular/common";
import { SharedDataService } from "src/app/services/shared-data/shared-data.service";
import { Subscription } from "rxjs";
import { MediaManagerTableView } from "./media-manager-table-view/media-manager-table-view.component";
import { debug } from "console";

@Component({
  selector: "app-media-manager",
  templateUrl: "./media-manager.component.html",
  styleUrls: ["./media-manager.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MediaManagerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MediaManagerTableView)
  mediaManagerTableView: MediaManagerTableView;

  @Input("teamID") teamID: number;

  @Input() actionButtons: {
    info: boolean;
    share: boolean;
    delete: boolean;
  } = {
      info: false,
      share: false,
      delete: false,
    };

  @Input() columnVisibility: {
    thumbnail: boolean;
    pdfStatus: boolean;
    fileType: boolean;
    owner: boolean;
    urlType: boolean;
    dateCreated: boolean;
    shares: boolean;
  } = {
      thumbnail: true,
      pdfStatus: false,
      fileType: true,
      owner: true,
      urlType: false,
      dateCreated: true,
      shares: false,
    };

  @Input() selectable: boolean = false;

  @Input() selectedMedia: any = [];

  //media that will be outbound to service
  public finalSelectedMedia: any = [];

  @Output() selected = new EventEmitter();

  @Input() showHeading: boolean = false;

  @Input() filterByType: string = "all";

  @Input() selectableType: string = "single";

  @Input() clickableThumb: boolean = false;

  @Input() defaultTab: string = "route";

  @Input() systemButtons: any = {
    action: null,
    cancel: null,
  };

  @Input() systemInteraction: string = "none";

  @Input() TargetEntity: any = null;

  @Output() parentActions = new EventEmitter();

  public clientSettings: any;
  public clientCode: string;

  public loading: {
    //keep track of tab loading
    allMedia: boolean;
  } = {
      allMedia: true,
    };
  public tableLoading: {
    //keep track of table loading
    allMedia: boolean;
  } = {
      allMedia: true,
    };
  //keep track of initial load
  public initialLoad: {
    allMedia: boolean;
  } = {
      allMedia: true,
    };
  //keep track of empty media sets
  public emptyMedia: {
    allMedia: boolean;
  } = {
      allMedia: false,
    };
  //conditions
  public mediaSharing: boolean = false;

  //content vars
  public copy: string;

  //security vars
  public labels: any;
  public token: string;
  public errorRetrievingMsg: string = "";

  //guardrail settings
  public guardrailSettings: any;

  //incoming assets
  public media: any = [];

  public user: any;
  public filterObjOption: any;

  //keep track of sorting
  public sortOrder: string = "desc";
  public sortBy: string = "date";

  public loadMore: boolean = false;
  public quantityChange: boolean = false;

  //subscriptions
  private shareDataSubscription: Subscription;

  public tab_current_page = 1;
  public tab_current_total = {
    allMedia: 0,
  }

  public totalPages = {
    allMedia: 0,
  };
  public currentPage = {
    allMedia: 1,
  };

  //user feedback
  public errorMsg: string = "";
  public statusMsg: string = "";
  public actionMsg: string = "";

  //holds incoming selected values
  public attached: any[] = [];
  //track inital attached (to check for removals)
  public initialAttached: [] = [];
  //to hold data converted to a form-like object
  public formStyleData: any = { myOptionsArray: [] };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _settingsService: SettingsService,
    private _xrPlatformRestService: XrPlatformRestService,
    private eventMediaVersion3Service: EventMediaVersion3Service,
    private location: Location,
    private _sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.copy = "Manage media assets.";
    this.retrieveLabels();
    this.copy = "Manage " + this.labels.media.plural;
    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveUser();

    if (this.teamID == null) {
      this.retrieveTeamID();
    }

    console.log("this.teamID in media manager", this.teamID);

    this.clientSettings = this._settingsService.getSettingsFromStorage(
      this.teamID
    );

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {

        if (incoming.type === "buttonAction") {

          console.log("incoming in table view", incoming);

          if (incoming.data.buttonAction !== undefined) {
            switch (incoming.data.buttonAction) {
              case "upload_media":
                this.mediaManagerTableView.openAlternateUploadModal();
                break;
              case "upload_3d_object":
                this.mediaManagerTableView.openModelViewerUpload();
                break;
            }
          }
        }
      });
  }

  ngAfterViewInit() {
    this.loading.allMedia = true;
    this.initiateMedia();
  }

  ngOnDestroy(): void {
    this.shareDataSubscription.unsubscribe();
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  public async initiateMedia() {

    console.log("this.targetEntity in initiateMedia", this.TargetEntity);

    //reset sort order
    this.sortBy = "date";
    this.sortOrder = "desc";

    if (this.loading.allMedia) {
      const reqObj = {
        page: 1,
        search: {
          name: "",
        },
        filters: {
          type: this.filterByType,
          owner_id: -1,
          created_month_year: "all",
          size: "all",
          visibility: "all",
          uploaded_on: "all",
          event_id: this.selectable && (this.selectableType === 'multiple' && this.systemInteraction !== "settings_media_multiple") && this.TargetEntity !== undefined && this.TargetEntity !== null ? this.TargetEntity.id : -1,
          self: false
        },
        sorting: {
          type: this.sortBy,
          direction: this.sortOrder,
        },
      };

      console.log("retrieve all media in get active tab")
      this.filterObjOption = reqObj;

      let getMedia = await this.retrieveAllMedia(reqObj).catch((error) => {
        this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
      });

      this.processMedia(getMedia, true, false);

      this.guardrailSettings = await this.retrieveGuardrailSettings().catch((error) => {
        this.errorRetrievingMsg = `Issue retrieving guardrail settings. Please refresh the screen and try again, or reach out to support`;
      });

      switch (this.systemInteraction) {
        case "event_media":
          if (this.TargetEntity === undefined) {
            this.finishLoading(true);
            return;
          }

          let retireveEventMedia = await this.eventMediaVersion3Service
            .retrieveMedia(this.teamID, this.token, this.TargetEntity.id, false).catch((error) => {
              this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
            });

          //if we get a success message back, continue as planned
          if (retireveEventMedia && retireveEventMedia.status === "success") {
            this.attached = retireveEventMedia.attached.assets;
            this.finishLoading(turnState);
          } else {
            this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
          }

          break;
        case "settings_media_multiple":
        case "settings_media":
          this.processSingleSelectedMedia(true);
          break;
        default:
          this.finishLoading(true);
      }
    } else {

      console.log("media in get active tab", this.media);

      //reload pagination
      this.tab_current_total.allMedia = this.totalPages.allMedia
      console.log("this.currentpage in get active tab", this.currentPage)
      console.log("this.totalpages in get active tab", this.totalPages)

    }
  }

  retrieveAllMedia(reqObj) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let getMedia = this._xrPlatformRestService.restfulAPIQuery(
      `/assets/team/${this.teamID}/filtered`,
      "post",
      reqObj,
      getOptions
    );

    return getMedia.toPromise();
  }

  private retrieveGuardrailSettings() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      `/guardrail-types-formatted`,
      "get",
      {},
      getOptions
    ).toPromise();


  }

  private processMedia(response, isFromFilterType, loadMore) {
    console.log("processing Media");
    let allMedia = response.assets;

    // allMedia = JSON.parse(JSON.stringify(allMedia)).sort(
    //   (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
    // );
    if (!allMedia.length && this.initialLoad.allMedia)
      this.emptyMedia.allMedia = true;
    this.initialLoad.allMedia = false;

    if (loadMore) this.media = allMedia;
    else if (
      isFromFilterType &&
      (this.loading.allMedia || this.tableLoading.allMedia)
    ) {
      this.media = allMedia;
    } else if (isFromFilterType && this.media.length >= 0)
      this.media = allMedia;
    this.totalPages.allMedia = response.total_assets;
    this.tab_current_total.allMedia = response.total_assets;
    if (this.selectableType != "multiple") this.loading.allMedia = false;
  }

  private finishLoading(loadmore: boolean = false) {
    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);

    if (loadmore) this.loadMore = true;
    this.loading.allMedia = false;
    this.tableLoading.allMedia = false;
  }

  private processSingleSelectedMedia(loadmore) {
    console.log("clickSelected in processSingleSelectedMedia", this.selectedMedia);
    this.attached = this.selectedMedia;
    this.finishLoading(loadmore);
  }

  public async loadMoreItems(e) {
    console.log("e.targetPage in loadMoreItems()", e.targetPage);
    this.tab_current_page = e.targetPage;
    this.currentPage.allMedia = e.targetPage;
    this.tableLoading.allMedia = true;

    const reqObj = {
      page: this.tab_current_page,
      search: {
        name: this.filterObjOption ? this.filterObjOption.search.name : "",
      },
      filters: {
        type: this.filterObjOption ? this.filterObjOption.filters.type : "all",
        size: this.filterObjOption ? this.filterObjOption.filters.size : "all",
        owner_id: this.filterObjOption
          ? this.filterObjOption.filters.owner_id
          : -1,
        created_month_year: "all",
        uploaded_on: this.filterObjOption.filters.uploaded_on,
        event_id: this.filterObjOption.filters.event_id,
        visibility: this.filterObjOption ? this.filterObjOption.filters.visibility : "all",
        self: false
      },
      sorting: {
        type: this.sortBy,
        direction: this.sortOrder,
      },
    };
    console.log("retrieve all media in loadMoreItems")

    let getMedia = await this.retrieveAllMedia(reqObj).catch((error) => {
      this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
    });

    this.processMedia(getMedia, false, true);
    this.finishLoading(true);
  }

  public async onFilterChange(e) {
    console.log("e in onFilterChange()", e);

    //reset loadmore
    this.loadMore = false;

    this.tab_current_page = 1;
    this.currentPage.allMedia = 1;
    this.tableLoading.allMedia = true;

    const reqObj = {
      page: !e.resetPageNum ? 1 : e.resetPageNum,
      search: {
        name: e.searchText,
      },
      filters: {
        type: e.fileType,
        owner_id: e.owner,
        created_month_year: e.date,
        uploaded_on: e.uploadedOn,
        event_id: e.eventID,
        size: e.size,
        visibility: e.visibility,
        self: false
      },
      sorting: {
        type: this.sortBy,
        direction: this.sortOrder,
      },
    };

    console.log("reqObj in onFilterChange()", reqObj);
    this.filterObjOption = reqObj;

    console.log("retrieve all media in onFilterChange")

    let getMedia = await this.retrieveAllMedia(reqObj).catch((error) => {
      this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
    });

    this.processMedia(getMedia, true, false);
    this.finishLoading(true);
  }

  public async onSortChange(e) {
    console.log("e in onSortChange()", e);

    this.sortBy = e.sortBy;
    this.sortOrder = e.sortOrder;
    this.tab_current_page = 1;
    this.currentPage.allMedia = 1;
    this.tableLoading.allMedia = true;

    //call here to /team/{{teamID}}/assets/filtered with updated sorting
    const reqObj = {
      page: !e.resetPageNum ? 1 : e.resetPageNum,
      search: {
        name: this.filterObjOption ? this.filterObjOption.search.name : "",
      },
      filters: {
        type: this.filterObjOption ? this.filterObjOption.filters.type : "all",
        owner_id: this.filterObjOption
          ? this.filterObjOption.filters.owner_id
          : -1,
        created_month_year: "all",
        size: this.filterObjOption ? this.filterObjOption.filters.size : "all",
        visibility: this.filterObjOption ? this.filterObjOption.filters.visibility : "all",
        uploaded_on: this.filterObjOption.filters.uploaded_on,
        event_id: this.filterObjOption.filters.event_id,
        self: false
      },
      sorting: {
        type: this.sortBy,
        direction: this.sortOrder,
      },
    };

    this.filterObjOption = reqObj;

    console.log("retrieve all media in onSortChange")

    let getMedia = await this.retrieveAllMedia(reqObj).catch((error) => {
      this.errorRetrievingMsg = `Issue retrieving ${this.labels.media.plural}. Please refresh the screen and try again, or reach out to support`;
    });

    this.processMedia(getMedia, true, false);
    this.finishLoading(true);
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  public handleUpdatedMedia(incoming) {
    let updatedMedia = [];

    console.log("incoming in handleUpdatedMedia", incoming);
    console.log("clickSelected selectedMedia in handleUpdatedMedia", this.selectedMedia);

    if (incoming.type === "add") {
      this.quantityChange = true;
      this.media.unshift(incoming.asset);
      //remove last index from this.media
      //this.media.pop();

      if (this.systemInteraction === "event_media" || this.systemInteraction === "settings_media_multiple") {

        this.media.map((mediaItem) => {
          if (mediaItem.id === incoming.asset.id) {
            mediaItem.selected = true;
          }
          return mediaItem;
        });

        let selectedMedia = [];

        this.media.forEach(mediaItem => {

          if (mediaItem.selected) selectedMedia.push(mediaItem);

        });

        this.selectedMedia = selectedMedia;

      } else if (this.systemInteraction === "settings_media") {
        let selectedMedia = incoming.asset
        selectedMedia.selected = true

        this.selectedMedia = [incoming.asset];

        this.media.map((mediaItem) => {
          if (mediaItem.id === incoming.asset.id) {
            mediaItem.selected = true;
          } else {
            mediaItem.selected = false;
          }
          return mediaItem;
        });

        this.selected.emit(incoming.asset);
      }

    } else if (incoming.type === "delete") {
      this.quantityChange = true;
      updatedMedia = this.media.filter((mediaItem) => {
        return mediaItem.id !== incoming.asset.id;
      });

      this.media = updatedMedia;
    } else if (incoming.type === "update") {

      console.log("incoming in update", incoming);

      updatedMedia = this.media
        .map((mediaItem) => {
          if (mediaItem.id === incoming.asset.id) {
            mediaItem = incoming.asset;
          }
          return mediaItem;
        })
        .filter((mediaItem) => {
          if (mediaItem.id !== incoming.asset.id) return true;

          //check if we are in private mode or publice mode and remove the media if needed
          if (!this.filterObjOption) return true;

          if (this.filterObjOption) console.log("this.filterObjOption.filters.visibility", this.filterObjOption.filters.visibility);
          console.log("mediaItem in update", mediaItem);

          if (this.filterObjOption.filters.visibility === "public" && !mediaItem.public) return false;

          if (this.filterObjOption.filters.visibility === "private" && mediaItem.public) return false;

          return true;
        });

      this.media = updatedMedia;
    } else if (incoming.type === "add_thumbnail") {
      updatedMedia = this.media.map((mediaItem) => {
        if (mediaItem.id === incoming.asset.id) {
          mediaItem.thumbnail_asset_url = incoming.thumbnail.cdn_uri;
          mediaItem.thumbnail_asset_uuid = incoming.thumbnail.uuid;
        }

        return mediaItem;
      });

      this.media = updatedMedia;

    }
  }

  public updateSelected(inbound) {
    this.finalSelectedMedia = inbound.selected;
    this.selectedMedia = inbound.selected;

    console.log("clickSelected in updateSelected", JSON.parse(JSON.stringify(this.selectedMedia)));
    console.log("clickSelected this.systemInteraction in updateSelected", this.systemInteraction);

    if (this.systemInteraction === "event_media") this.updateEventMedia();
    if (this.systemInteraction === "settings_media" || this.systemInteraction === "settings_media_multiple") this.manageActions();
  }

  public closeParentModal() {
    let outbound = {
      action: "closeModal",
    };

    this.parentActions.emit(outbound);
  }

  public manageActions() {
    switch (this.systemInteraction) {
      case "event_media":
        //@todo: move update event media here
        //this.updateEventMedia();
        break;
      case "settings_media":
        let outgoingMedia = this.selectedMedia.filter((mediaItem) => {
          return mediaItem.selected;
        });

        this.selected.emit(outgoingMedia[0]);
        break;
      case "settings_media_multiple":
        let outgoingMediaMultiple = this.selectedMedia.filter((mediaItem) => {
          return mediaItem.selected;
        });

        console.log("clickSelected outgoindMediaMultiple in manageActions", JSON.parse(JSON.stringify(outgoingMediaMultiple)));

        this.selected.emit(outgoingMediaMultiple);
        break;
    }
  }

  private updateEventMedia() {
    let media = [];
    media = this.media;

    let outgoing = {
      action: "update_event_media",
      media: media,
      attached: [...this.initialAttached, ...this.finalSelectedMedia],
    };

    this.parentActions.emit(outgoing);
  }

  public hideStatus(type) {
    if (type === "error") {
      this.errorMsg = "";
    } else {
      this.statusMsg = "";
    }
  }
}
