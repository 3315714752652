<div class="setup-account-wrapper">
  <mdb-card>
    <mdb-card-header class="theme-base-medium-dark white-text text-center py-4">
      <h5><strong>Create Your New Account</strong></h5>
    </mdb-card-header>
    <mdb-card-body>
      <form
        [formGroup]="setupForm"
        *ngIf="!formLoading"
        autocomplete="disabled"
      >
        <div class="row p-0">
          <div class="col-12">
            <p>
              <span class="action-msg-small">Required*</span>
            </p>
          </div>
        </div>
        <div class="md-form">
          <input
            type="text"
            id="email"
            class="form-control"
            formControlName="email"
            mdbInput
            mdbValidate
          />
          <label for="email">* Confirm Email</label>
          <mdb-error
            *ngIf="
              setupForm.controls.email.invalid &&
              (setupForm.controls.email.dirty ||
                setupForm.controls.email.touched)
            "
          >
            <ng-container
              *ngIf="!setupForm.controls.email.errors.email_mismatch"
              >Please confirm your email</ng-container
            >
            <ng-container *ngIf="setupForm.controls.email.errors.email_mismatch"
              >Email mismatch: please make sure this is the email that received
              the invitation.</ng-container
            >
          </mdb-error>
        </div>
        <div class="md-form">
          <input
            type="text"
            id="username"
            class="form-control"
            formControlName="username"
            mdbInput
            mdbValidate
          />
          <label for="username">* Username</label>
          <mdb-error
            *ngIf="
              setupForm.controls.username.invalid &&
              (setupForm.controls.username.dirty ||
                setupForm.controls.username.touched)
            "
          >
            <ng-container
              *ngIf="!setupForm.controls.username.errors.username_exists"
              >Please add a username</ng-container
            >
            <ng-container
              *ngIf="setupForm.controls.username.errors.username_exists"
              >Username already exists</ng-container
            >
          </mdb-error>
        </div>
        <div class="row">
          <div class="grid">
            <div class="grid-item">
              <div class="md-form">
                <!--first name-->
                <input
                  type="text"
                  id="first_name"
                  class="form-control"
                  formControlName="first_name"
                  mdbInput
                  mdbValidate
                  autocomplete="disabled"
                />
                <label for="first_name">* First name</label>
                <mdb-error
                  *ngIf="
                    setupForm.controls.first_name.invalid &&
                    (setupForm.controls.first_name.dirty ||
                      setupForm.controls.first_name.touched)
                  "
                  >Please add a first name</mdb-error
                >
              </div>
            </div>
            <div class="grid-item">
              <div class="md-form">
                <!--last name-->
                <input
                  type="text"
                  id="last_name"
                  class="form-control"
                  formControlName="last_name"
                  mdbInput
                  mdbValidate
                  autocomplete="disabled"
                />
                <label for="last_name">* Last name</label>
              </div>
            </div>
          </div>
        </div>
        <div class="md-form">
          <mat-form-field
            appearance="outline"
            style="width: 100%"
            [color]="passwordComponentWithConfirmation.color"
          >
            <mat-label>Password</mat-label>
            <mat-pass-toggle-visibility
              #toggle
              matSuffix
            ></mat-pass-toggle-visibility>
            <input
              matInput
              #passwordWithConfirmation
              [type]="toggle.type"
              required
              placeholder="Password"
              formControlName="password"
            />
            <mat-hint align="end" aria-live="polite">
              {{ passwordWithConfirmation.value.length }} /
              {{ passwordComponentWithConfirmation.max }}
            </mat-hint>

            <mat-error *ngIf="setupForm.controls.password.hasError('required')">
              Password is required
            </mat-error>
            <mat-error *ngIf="setupForm.controls.password.hasError('pattern')">
              Password is not valid
            </mat-error>
          </mat-form-field>
          <!--@angular-material-extensions/password-strength's main component-->
          <mat-password-strength
            #passwordComponentWithConfirmation
            [enableSpecialCharRule]="false"
            (onStrengthChanged)="onStrengthChanged($event)"
            [password]="passwordWithConfirmation.value"
          >
          </mat-password-strength>
          <!--Password's strength info-->
          <mat-password-strength-info
            [passwordComponent]="passwordComponentWithConfirmation"
          >
          </mat-password-strength-info>

          <!--password input field for confirmation-->
          <mat-form-field appearance="outline" class="mt-3" style="width: 100%">
            <mat-label>Confirm Password</mat-label>
            <mat-pass-toggle-visibility
              #toggleConfirmPassword
              matSuffix
            ></mat-pass-toggle-visibility>
            <input
              matInput
              #passwordToConfirm
              [type]="toggleConfirmPassword.type"
              required
              placeholder="Password"
              [formControl]="
                passwordComponentWithConfirmation.passwordConfirmationFormControl
              "
            />

            <!-- password hint-->
            <mat-hint align="end" aria-live="polite">
              {{ passwordToConfirm.value.length }} /
              {{ passwordComponentWithConfirmation.max }}
            </mat-hint>

            <!-- password error msgs-->
            <!-- <mat-error
              *ngIf="setupForm.controls.confirm_password.hasError('required')"
            >
              Password confirmation is required
            </mat-error>
            <mat-error
              *ngIf="
                setupForm.controls.confirm_password.hasError('notConfirmed')
              "
            >
              Password is not the same
            </mat-error> -->
            <mat-error
              *ngIf="
                passwordComponentWithConfirmation.passwordConfirmationFormControl.hasError(
                  'required'
                )
              "
            >
              Password confirmation is required
            </mat-error>
            <mat-error
              *ngIf="
                passwordComponentWithConfirmation.passwordConfirmationFormControl.hasError(
                  'notConfirmed'
                )
              "
            >
              Password is not the same
            </mat-error>
          </mat-form-field>
        </div>
        <div class="md-form">
          <mdb-checkbox formControlName="terms">
            I agree with the
            <a href="https://foretellreality.com/" target="_blank"
              >terms and conditions</a
            >
          </mdb-checkbox>
        </div>
        <div class="buttons-wrapper">
          <button
            *ngIf="
              formLoading || !setupForm.valid || isClean || !passwordConfirmd
            "
            mdbBtn
            type="submit"
            mdbWavesEffect
            class="theme-modal-button btn looks-disabled"
            id="sign-in"
            (click)="triggerValidations()"
          >
            Submit
          </button>
          <button
            *ngIf="
              !formLoading && setupForm.valid && !isClean && passwordConfirmd
            "
            mdbBtn
            type="submit"
            mdbWavesEffect
            class="theme-modal-button btn"
            id="sign-in"
            (click)="setupAccount()"
            [disabled]="formState === 'processing'"
          >
            Submit
          </button>
        </div>
      </form>
    </mdb-card-body>
  </mdb-card>
  <p class="text-center mt-2">
    Invite expired?
    <button
      color="link"
      class="btn btn-clear inline color-theme-base-medium-light cursor-pointer"
      style="cursor: pointer"
      (click)="requestNewInvite()"
    >
      Request a new one here.
    </button>
  </p>
</div>
