import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetricsLandingComponent } from "./metrics-landing/metrics-landing.component";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { PersistentModule } from "./../persistent/persistent.module";
import { MetricsInteractiveComponent } from './metrics-interactive/metrics-interactive.component';
import { SpeakingLineBreakdownComponent } from "./metrics-interactive/graphs/speaking-line-breakdown/speaking-line-breakdown.component";
import { SessionBreakdownComponent } from './metrics-interactive/session-breakdown/session-breakdown.component';
import { ConnectionLineBreakdownComponent } from "./metrics-interactive/graphs/connection-line-breakdown/connection-line-breakdown.component";
import { GazingBreakdownComponent } from './metrics-interactive/graphs/gazing-breakdown/gazing-breakdown.component';
import { HttpClientModule } from "@angular/common/http";
import { TabsModule } from "ng-uikit-pro-standard";
import { UserInventoryBreakdownComponent } from './metrics-interactive/graphs/user-inventory-breakdown/user-inventory-breakdown.component';
import { DataEntryBreakdownComponent } from './metrics-interactive/graphs/data-entry-breakdown/data-entry-breakdown.component';
import { UserInventoryBreakdownTableComponent } from './metrics-interactive/graphs/user-inventory-breakdown-table/user-inventory-breakdown-table.component';
import { MetricsPerformanceComponent } from "./metrics-performance/metrics-performance.component";
import { MetricsReadquestComponent } from './metrics-readquest/metrics-readquest.component';
import { ReadquestAnalyticsDrillDownSingleUserComponent } from "./metrics-readquest/drilldown/readquest-analytics-drill-down-single-user/readquest-analytics-drill-down-single-user.component";
import { ReadquestAnalyticsDrillDownSingleUserSkillComponent } from "./metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-skill/readquest-analytics-drill-down-single-user-skill.component";
import { ReadquestAnalyticsDrillDownSingleSkillComponent } from './metrics-readquest/drilldown/readquest-analytics-drill-down-single-skill/readquest-analytics-drill-down-single-skill.component';
import { MetricsReadquestSkillsComponent } from './metrics-readquest/drilldown/metrics-readquest-skills/metrics-readquest-skills.component';
import { CustomNoRowsOverlayComponent } from './metrics-readquest/parts/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { ReadquestAnalayticsDrillDownSingleUserSkillPhonemeAccuracyComponent } from './metrics-readquest/drilldown/readquest-analaytics-drill-down-single-user-skill-phoneme-accuracy/readquest-analaytics-drill-down-single-user-skill-phoneme-accuracy.component';
import { ReadquestAnalyticsDrillDownSingleUserPhonemeAccuracyComponent } from './metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-phoneme-accuracy/readquest-analytics-drill-down-single-user-phoneme-accuracy.component';
import { ReadquestAnalyticsDrillDownSingleUserWordAccuracyComponent } from './metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-word-accuracy/readquest-analytics-drill-down-single-user-word-accuracy.component';
import { ReadquestAnalyticsDrillDownSingleUserSkillWordAccuracyComponent } from './metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-skill-word-accuracy/readquest-analytics-drill-down-single-user-skill-word-accuracy.component';
import { MetricsAiaComponent } from './metrics-aia/metrics-aia.component';
import { MetricsAiaByEventComponent } from './metrics-aia/drilldown/metrics-aia-by-event/metrics-aia-by-event.component';
import { MetricsAiaByUserComponent } from './metrics-aia/drilldown/metrics-aia-by-user/metrics-aia-by-user.component';
import { MetricsAiaSingleSessionComponent } from './metrics-aia/drilldown/metrics-aia-single-session/metrics-aia-single-session.component';
import { AgRendererClickableIconComponent } from './metrics-aia/renderers/ag-renderer-clickable-icon/ag-renderer-clickable-icon.component';
import { MetricsAiaAssessmentComponent } from './metrics-aia/drilldown/metrics-aia-assessment/metrics-aia-assessment.component';
import { AgRendererScoreBarComponent } from './metrics-aia/renderers/ag-renderer-score-bar/ag-renderer-score-bar.component';

@NgModule({
    declarations: [
        MetricsLandingComponent,
        MetricsInteractiveComponent,
        SpeakingLineBreakdownComponent,
        SessionBreakdownComponent,
        ConnectionLineBreakdownComponent,
        GazingBreakdownComponent,
        UserInventoryBreakdownComponent,
        DataEntryBreakdownComponent,
        UserInventoryBreakdownTableComponent,
        MetricsPerformanceComponent,
        MetricsReadquestComponent,
        ReadquestAnalyticsDrillDownSingleUserComponent,
        ReadquestAnalyticsDrillDownSingleUserSkillComponent,
        ReadquestAnalyticsDrillDownSingleSkillComponent,
        MetricsReadquestSkillsComponent,
        CustomNoRowsOverlayComponent,
        ReadquestAnalayticsDrillDownSingleUserSkillPhonemeAccuracyComponent,
        ReadquestAnalyticsDrillDownSingleUserPhonemeAccuracyComponent,
        ReadquestAnalyticsDrillDownSingleUserWordAccuracyComponent,
        ReadquestAnalyticsDrillDownSingleUserSkillWordAccuracyComponent,
        MetricsAiaComponent,
        MetricsAiaByEventComponent,
        MetricsAiaByUserComponent,
        MetricsAiaSingleSessionComponent,
        AgRendererClickableIconComponent,
        MetricsAiaAssessmentComponent,
        AgRendererScoreBarComponent,
    ],
    imports: [CommonModule, SharedModulesModule, PersistentModule, HttpClientModule, TabsModule.forRoot()],
    exports: [MetricsLandingComponent]
})
export class MetricsModule { }
