import { CoolLocalStorage } from '@angular-cool/storage';
import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  //persistent
  public teamID: number = 0;
  public clientcode: string = "";

  //form vars
  public forgotPasswordForm: UntypedFormGroup;
  public formLoading: boolean = true;
  public isClean: boolean = true;
  public formState: string = "pending";
  public notification: any;
  public preSelected: {
    email: string;
  } = {
      email: ""
    }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {

      // Get the client code
      this.clientcode = params.get('client_code');

      this.retrieveData();

    });
  }

  private async retrieveData() {

    let getteamID = await this.retrieveTeamIDByClientCode().catch((error) => {
      this._notificationService.errorNotification("Error retrieving team ID");
    });

    this.teamID = getteamID["team_id"];

    this.buildForm();

  }

  private retrieveTeamIDByClientCode() {
    //retrieve team ID by client code
    const headers = {
      "Content-Type": "application/json",
    };

    const httpOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      `/team/id/by-code/${this.clientcode}`,
      "get",
      null,
      httpOptions
    ).toPromise();
  }

  private buildForm() {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.preSelected.email, [
        Validators.required,
        Validators.email
      ])
    });

    this.formLoading = false;
  }

  public async submitForm() {
    this.formState = "pending";
    this.notification = this._notificationService.savingNotification("Sending password reset email");

    if (this.forgotPasswordForm.valid) {

      const headers = {
        "Content-Type": "application/json",
      };

      const httpOptions = {
        headers: headers,
      };

      let data = {
        email: this.forgotPasswordForm.value.email,
      }

      let response = await this._xrPlatformRestService.restfulAPIQuery(
        `/users/cognito/${this.teamID}/reset-password`,
        "post",
        data,
        httpOptions
      ).toPromise().catch((error) => {
        this.formState = "pending";
        this._notificationService.clearNotification(this.notification);
        this._notificationService.successNotification("If your email is in our system, you will receive a password reset email shortly");
      });

      console.log("response from reset password", response);

      if (response && response["status"] == "OK") {
        this.formState = "pending";
        this._notificationService.clearNotification(this.notification);
        this._notificationService.successNotification("If your email is in our system, you will receive a password reset email shortly");

        setTimeout(() => {
          this.router.navigate([`/client/${this.clientcode}/login`]);
        }, 3000);
      } else {
        this.formState = "pending";
        this._notificationService.clearNotification(this.notification);

        if (response["error"] && response["error"] === "existing_user") {
          this._notificationService.errorNotification("For this email, you will need to contact support for a password update.", 12000);
        } else {
          this._notificationService.successNotification("If your email is in our system, you will receive a password reset email shortly");
        }

      }

    } else {
      this.formState = "pending";
      this._notificationService.clearNotification(this.notification);
      this._notificationService.successNotification("If your email is in our system, you will receive a password reset email shortly");
    }

  }
}
