<div class="interactive-analytics-card aia-analytics">
  <div
    class="d-flex justify-content-between align-items-center w-100 metrics-controls"
  >
    <div class="back-button">
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="backRoute"
        [queryParams]="queryParams"
        mdbTooltip="Back to AIA Session"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
  </div>
  <div class="readquest-analytics-highlevel analytics-card">
    <ng-container *ngIf="gridLoading">
      <div class="row">
        <div class="col-12">
          <!-- if content hasnt load -->
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg === ''"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg !== ''"
            >
              <div
                class="alert alert-danger"
                role="alert"
                [innerHTML]="errorRetrievingMsg"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading">
      <mdb-tabset
        #availableTabs
        id="availableTabs"
        [buttonClass]="'classic-tabs'"
        class="admin-tab-styles"
        contentClass="remove-card available-tabs h-100"
      >
        <mdb-tab heading="Using Assessment Criteria"
          ><ag-grid-angular
            style="width: 100%; height: 84vh"
            [columnDefs]="columnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No assessment criteria available"
          ></ag-grid-angular
        ></mdb-tab>
        <mdb-tab heading="Using Custom Rubrics">
          <ag-grid-angular
            style="width: 100%; height: 84vh"
            [columnDefs]="rubricColumnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [rowData]="rubricRowData"
            [class]="themeClass"
            overlayNoRowsTemplate="No custom rubrics available"
          ></ag-grid-angular>
        </mdb-tab>
      </mdb-tabset>
    </ng-container>
  </div>
</div>
