import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { MenuComponent } from "./menu/menu.component";
import { LogoutComponent } from "./menu/modals/logout/logout.component";
import { FooterComponent } from "./footer/footer.component";
import { TextInlineEditComponent } from "./inline-edit/text-inline-edit/text-inline-edit.component";
import { AutofocusDirective } from "src/app/directives/autofocus.directive";
import { MediaPreviewDirective } from "src/app/directives/media-preview.directive";
import { StatusMessagesComponent } from "./forms/status-messages/status-messages.component";
import { DebounceClickDirective } from "src/app/directives/debounce-click.directive";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { CloseConfirmationComponent } from "./close-confirmation/close-confirmation.component";
import { CloseConfirmationModalComponent } from "./close-confirmation/close-confirmation-modal/close-confirmation-modal.component";
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { TrimInputDirective } from "src/app/directives/trim-input.directive";

@NgModule({
    declarations: [
        MenuComponent,
        LogoutComponent,
        FooterComponent,
        TextInlineEditComponent,
        AutofocusDirective,
        MediaPreviewDirective,
        TrimInputDirective,
        DebounceClickDirective,
        StatusMessagesComponent,
        SideMenuComponent,
        CloseConfirmationComponent,
        CloseConfirmationModalComponent,
        CustomToastComponent
    ],
    imports: [CommonModule, SharedModulesModule],
    exports: [
        MenuComponent,
        FooterComponent,
        TextInlineEditComponent,
        AutofocusDirective,
        MediaPreviewDirective,
        TrimInputDirective,
        DebounceClickDirective,
        StatusMessagesComponent,
        SideMenuComponent,
        CloseConfirmationComponent,
        CloseConfirmationModalComponent,
        CustomToastComponent
    ]
})
export class PersistentModule { }
