import { Component } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';

@Component({
  selector: 'app-metrics-aia',
  templateUrl: './metrics-aia.component.html',
  styleUrls: ['./metrics-aia.component.scss'],
  providers: [TitleCasePipe]
})
export class MetricsAiaComponent {
  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public title: string = "AIA Reporting Dashboard";
  public users: any;
  private targetURL: string;

  //icons
  public faTimes = faTimes;

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //date/time
  public tz: any;
  public tz_iana: any;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveClientCode();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;

    this.buildColumnDefs();

    this.targetURL = `foretell/display/aia/sessions/${this.teamID}`;

    this.retrieveData();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'Scenario', field: 'event_name', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'text-decoration': 'underline', 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Username', field: 'username', type: 'text', filter: "agTextColumnFilter", cellStyle: { 'text-decoration': 'underline', 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Start', field: 'start_time', type: 'date'
      },
      {
        headerName: 'End', field: 'end_time', type: 'date'
      },
      {
        headerName: '# of Practice Sessions', field: 'practice_session_count', type: 'number', filter: 'agNumberColumnFilter', cellStyle: { 'text-decoration': 'underline', 'cursor': 'pointer' }, filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Submitted Files', field: 'submitted_files', type: "boolean", cellStyle: { 'text-decoration': 'underline', 'cursor': 'pointer' }
      }
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  private async retrieveData() {
    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.rowData = gridData.asset_sessions;

    console.log("this.rowData", this.rowData);

    this.gridLoading = false;
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {}

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions, true).toPromise();

  }

  public onCellClicked(event) {

    let thisColumn = event.colDef.field;
    console.log("event on cell clicked", event);

    if (thisColumn === "event_name") {
      this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'scenario', event.data['event_id']], {});
    } else if (thisColumn === "username") {
      this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'user', event.data['user_id']], {});
    } else {
      this.router.navigate(['client', this.clientCode, 'analytics', 'aia', 'session', event.data['session_id']], {});
    }


  }
}
