<div class="forgot-password-wrapper">
  <mdb-card>
    <mdb-card-header class="theme-base-medium-dark white-text text-center py-4">
      <h5><strong>Request a Password Reset</strong></h5>
    </mdb-card-header>
    <mdb-card-body>
      <form
        [formGroup]="forgotPasswordForm"
        *ngIf="!formLoading"
        autocomplete="disabled"
      >
        <div class="row p-0">
          <div class="col-12">
            <p>
              <span class="action-msg-small">Required*</span>
            </p>
          </div>
        </div>
        <div class="md-form">
          <mat-form-field
            appearance="outline"
            style="width: 100%"
            [color]="passwordComponentWithConfirmation.color"
          >
            <mat-label>Password</mat-label>
            <mat-pass-toggle-visibility
              #toggle
              matSuffix
            ></mat-pass-toggle-visibility>
            <input
              matInput
              #passwordWithConfirmation
              [type]="toggle.type"
              required
              placeholder="Password"
              formControlName="password"
            />
            <mat-hint align="end" aria-live="polite">
              {{ passwordWithConfirmation.value.length }} /
              {{ passwordComponentWithConfirmation.max }}
            </mat-hint>

            <mat-error
              *ngIf="forgotPasswordForm.controls.password.hasError('required')"
            >
              Password is required
            </mat-error>
            <mat-error
              *ngIf="forgotPasswordForm.controls.password.hasError('pattern')"
            >
              Password is not valid
            </mat-error>
          </mat-form-field>
          <!--@angular-material-extensions/password-strength's main component-->
          <mat-password-strength
            #passwordComponentWithConfirmation
            [enableSpecialCharRule]="false"
            (onStrengthChanged)="onStrengthChanged($event)"
            [password]="passwordWithConfirmation.value"
          >
          </mat-password-strength>
          <!--Password's strength info-->
          <mat-password-strength-info
            [passwordComponent]="passwordComponentWithConfirmation"
          >
          </mat-password-strength-info>

          <!--password input field for confirmation-->
          <mat-form-field appearance="outline" class="mt-3" style="width: 100%">
            <mat-label>Confirm Password</mat-label>
            <mat-pass-toggle-visibility
              #toggleConfirmPassword
              matSuffix
            ></mat-pass-toggle-visibility>
            <input
              matInput
              #passwordToConfirm
              [type]="toggleConfirmPassword.type"
              required
              placeholder="Password"
              [formControl]="
                passwordComponentWithConfirmation.passwordConfirmationFormControl
              "
            />

            <!-- password hint-->
            <mat-hint align="end" aria-live="polite">
              {{ passwordToConfirm.value.length }} /
              {{ passwordComponentWithConfirmation.max }}
            </mat-hint>

            <!-- password error msgs-->
            <!-- <mat-error
              *ngIf="forgotPasswordForm.controls.confirm_password.hasError('required')"
            >
              Password confirmation is required
            </mat-error>
            <mat-error
              *ngIf="
                forgotPasswordForm.controls.confirm_password.hasError('notConfirmed')
              "
            >
              Password is not the same
            </mat-error> -->
            <mat-error
              *ngIf="
                passwordComponentWithConfirmation.passwordConfirmationFormControl.hasError(
                  'required'
                )
              "
            >
              Password confirmation is required
            </mat-error>
            <mat-error
              *ngIf="
                passwordComponentWithConfirmation.passwordConfirmationFormControl.hasError(
                  'notConfirmed'
                )
              "
            >
              Password is not the same
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-center">
          <button
            *ngIf="
              formLoading ||
              !forgotPasswordForm.valid ||
              isClean ||
              !passwordConfirmd
            "
            mdbBtn
            type="submit"
            mdbWavesEffect
            class="theme-modal-button btn looks-disabled"
            id="sign-in"
            (click)="triggerValidations()"
          >
            Reset Password
          </button>
          <button
            *ngIf="
              !formLoading &&
              forgotPasswordForm.valid &&
              !isClean &&
              passwordConfirmd
            "
            mdbBtn
            type="submit"
            mdbWavesEffect
            class="theme-modal-button btn"
            id="sign-in"
            (click)="submitForm()"
            [disabled]="formState === 'processing'"
          >
            Reset Password
          </button>
        </div>
      </form></mdb-card-body
    ></mdb-card
  >
</div>
