import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { XrPlatformRestService } from '../../../../../services/rest/xr-platform/xr-platform-rest.service';
import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import {
  ColDef,
  ColGroupDef,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';

import { combineLatest } from 'rxjs';

import { environment } from "src/environments/environment";

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { CoolLocalStorage } from '@angular-cool/storage';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-metrics-aia-assessment',
  templateUrl: './metrics-aia-assessment.component.html',
  styleUrls: ['./metrics-aia-assessment.component.scss']
})
export class MetricsAiaAssessmentComponent {
  //incoming via param
  public recordingID = 0;
  public aiExperienceID: number = 0;
  public sessionID: number = 0;
  public targetType: string = "trainee";
  public aiModel: string = "gpt-4o";

  //query params
  public queryParams = {
    ai_model: "gpt-4o",
  };

  //nav related
  public backRoute: string[];
  public clientCode: string = "";

  //API related
  public targetSessionURL: string = "";
  public token: string = "";

  //transcript file storage
  public transcriptFile: string = "";  

  //icons
  public faTimes = faTimes;

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //proxying - temporary until we can resolve CORS on azure server
  private proxyURL: string = environment.proxyURL;

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public rubricColumnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitGridWidth",
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220,
    cellStyle: { 'display': 'flex', 'align-items': 'center', 'padding': '12px' }
  };
  public rowData!: any[];
  public rubricRowData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    private _metricsServicesService: MetricsServicesService,
    private coolLocalStorage: CoolLocalStorage,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.buildColumnDefs();
    this.retrieveToken();
    this.retrieveClientCode();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {
      this.sessionID = +params.get('session_id');
      this.targetType = params.get('assessment_target');

      this.targetSessionURL = `foretell/display/aia/session/${this.sessionID}`;

      this.aiExperienceID = +queryParams.get('ai_exp_id');
      this.recordingID = +queryParams.get('recording_id');
      this.aiModel = queryParams.get('ai_model');
      this.queryParams.ai_model = this.aiModel;

      this.backRoute = [
        '/client',
        this.clientCode,
        'analytics',
        'aia',
        'session',
        this.sessionID.toString(),
      ];


      console.log("this.aiExperienceID", this.aiExperienceID);

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }


  private async retrieveData() {

    let sessionData = await this.retrieveSessionData();

    console.log("session data in assessment", sessionData);

    let thisRecording = sessionData.asset_session.recording_sessions.find((recording: any) => recording.id === this.recordingID);

    console.log("thisRecording data in assessment", thisRecording);

    this.transcriptFile = thisRecording.generated_media_types.transcript_json[0].transcript_json;

    console.log("this.transcriptFile in assessment", this.transcriptFile);

    let assessmentData = await this.retrieveAssessmentData();

    // let assessmentData = this.coolLocalStorage.getObject(`assessmentData${this.targetType}`);

    // if (!assessmentData) 

    // this.coolLocalStorage.setObject(`assessmentData${this.targetType}`, assessmentData);

    // console.log("assessment data", assessmentData);

    let results = assessmentData["assessmentCriteriaAnalysis"].results;
    let rubricResults = assessmentData["rubricAnalysis"];

    //results is an object; iterate over the keys to get the data
    let data = [];

    for (let key in results) {
      let item = results[key];

      let thisItem = {
        criteria: key,
        description: item.description,
        rationale: item.rationale,
        assessment: item.assessment,
        score: item.score
      }

      data.push(thisItem);
    }
    this.rowData = data;

    data = []

    for (let key in rubricResults) {

      let item = rubricResults[key];

      let assessmentBlock = item.assessment;

      //need to split content by \n\n
      let content = assessmentBlock.split("\n\n");

      let thisItem = {
        criteria: item.title,
        description: content[0],
        assessment: content[1],
        rationale: content[2],
      }

      data.push(thisItem);

    }

    console.log("this.rubricRowData", data);

    this.rubricRowData = data;
    this.gridLoading = false;

  }

  private retrieveSessionData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {}

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetSessionURL, "get", data, getOptions, true).toPromise();

  }

  private retrieveAssessmentData() {
    const headers = {
      "Content-Type": "application/json",
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      "aiModel": this.aiModel,
      "analysisTarget": this.targetType,
      "aiExperienceId": this.aiExperienceID,
      "transcriptJson": this.transcriptFile
    }

    let getAssessment = this._xrPlatformRestService.restfulAPIQueryThirdParty(
      `https://ai-api.foretellreality.com/api/ai/analytics/compound_analysis_for_experience`,
      "post",
      data,
      getOptions
    );

    return getAssessment.toPromise();
  }

  private buildColumnDefs() {
    let columns = [
      {
        headerName: 'Criteria', field: 'criteria', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Description', field: 'description', type: 'text', cellStyle: { 'line-height': '24px' }, wrapText: true
      },
      {
        headerName: 'Rationale', field: 'rationale', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' },
      },
      {
        headerName: 'Assessment', field: 'assessment', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      }
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);

    let rubricColumns = [
      {
        headerName: 'Criteria', field: 'criteria', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' }, filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Description', field: 'description', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' },
      },
      {
        headerName: 'Assessment', field: 'assessment', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Rationale', field: 'rationale', type: 'text', wrapText: true, cellStyle: { 'line-height': '24px' },
      }
    ]

    this.rubricColumnDefs = this._metricsServicesService.createColumnDefinitions(rubricColumns);
  }

}
