import { CoolLocalStorage } from "@angular-cool/storage";
import { TitleCasePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import {
  IMyOptions,
  MDBDatePickerComponent,
  MDBModalRef,
  MDBModalService,
} from "ng-uikit-pro-standard";
import { DateFormatPipe } from "ngx-moment";
import { Subject } from "rxjs";
import { DateMustBeLater } from "src/app/modules/experience-management/modals/validators/date-must-be-later.directive";
import { TimeMustBeLater } from "src/app/modules/experience-management/modals/validators/time-must-be-later.directive";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { EditClientPasswordModalComponent } from "../edit-client-password-modal/edit-client-password-modal.component";

@Component({
  selector: "app-manage-client-modal",
  templateUrl: "./manage-client-modal.component.html",
  styleUrls: ["./manage-client-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ManageClientModalComponent implements OnInit {
  @ViewChild("datePickerStart", { static: false })
  datePickerStart: MDBDatePickerComponent;

  @ViewChild("datePickerEnd", { static: false })
  datePickerEnd: MDBDatePickerComponent;

  private outgoing: Subject<any> = new Subject();
  private token: string;

  public timeTracker: {
    start: number;
    end: number;
  } = {
      start: 0,
      end: 0,
    };
  public dateLabels: {
    end: string;
  } = {
      end: "Please select a start date first",
    };

  public action: string;
  public client: any;

  public preSelected: {
    name: string;
    domain: string;
    start_date: any;
    end_date: any;
    description: string;
    engagement_description: string;
    acknowledgement: boolean;
    password: string;
  } = {
      name: null,
      domain: null,
      start_date: null,
      end_date: null,
      description: null,
      engagement_description: null,
      acknowledgement: null,
      password: "",
    };

  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: " Client",
      reset: " Another Client",
      retry: "Retry",
    };
  public continueType: string;

  public tz: any = moment.tz(moment.tz.guess()).format("z");

  public times: {
    start: any;
    end: any;
  } = {
      start: moment().format("hh:mm A") + " " + this.tz,
      end: null,
    };

  public clientForm: UntypedFormGroup;

  public formLoading: boolean = true;
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, dd mmm yyyy",
    useDateObject: true,
  };
  public myEndDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "ddd, d mmm yyyy",
    useDateObject: true,
  };

  public msgs: {
    errorMsg: string;
    statusMsg: string;
    processingMsg: string;
    actionMsg: string;
  } = {
      errorMsg: "",
      statusMsg: "",
      processingMsg: "",
      actionMsg: "",
    };
  public formState: string = "active";

  public title: string = "";

  public passwordFrame: MDBModalRef;
  private modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  public fieldTextType: boolean;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public clientFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private elementRef: ElementRef,
    private TitleCase: TitleCasePipe,
    private modalService: MDBModalService
  ) { }

  ngOnInit(): void {
    this.title = this.action + " Client";
    this.btnLabel.main = this.action + this.btnLabel.main;
    this.btnLabel.reset = this.action + this.btnLabel.reset;
    this.retrieveToken();
    this.makeFormValidatingGroup();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private buildPreselected() {
    ;
    ;
    this.preSelected.start_date = new Date();
    if (this.action == "update") {
      if (this.client.engagements.length > 0) {
        this.times.start =
          moment(this.client.engagements[0].start_date).format("hh:mm A") +
          " " +
          this.tz;
        this.times.end =
          moment(this.client.engagements[0].end_date).format("hh:mm A") +
          " " +
          this.tz;
        this.preSelected.engagement_description =
          this.client.engagements[0].description;
        this.preSelected.end_date = new Date(this.client.engagements[0].end_date);
        this.preSelected.start_date = new Date(this.client.engagements[0].start_date);
      }

      this.preSelected.name = this.client.name;
      this.preSelected.domain = this.client.domain;
      this.preSelected.description = this.client.description;
      this.preSelected.acknowledgement = true;
      this.preSelected.password = "true";
    }
    ;
  }

  private makeFormValidatingGroup() {
    this.buildPreselected();

    this.clientForm = new UntypedFormGroup(
      {
        start_date: new UntypedFormControl(
          this.preSelected.start_date,
          Validators.required
        ),
        start_time: new UntypedFormControl(this.times.start, Validators.required),
        end_date: new UntypedFormControl(
          this.preSelected.end_date,
          Validators.required
        ),
        end_time: new UntypedFormControl(this.times.end, Validators.required),
        client_name: new UntypedFormControl(
          this.preSelected.name,
          Validators.required
        ),
        client_domain: new UntypedFormControl(
          this.action == "update"
            ? { value: this.preSelected.domain, disabled: true }
            : this.preSelected.domain,
          Validators.required
        ),
        description: new UntypedFormControl(this.preSelected.description),
        engagement_description: new UntypedFormControl(
          this.preSelected.engagement_description
        ),
        default_password: new UntypedFormControl(
          this.preSelected.password,
          Validators.required
        ),
        acknowledgement: new UntypedFormControl(
          this.preSelected.acknowledgement,
          Validators.required
        ),
      },
      [
        TimeMustBeLater("start_time", "end_time", "start_date", "end_date"),
        DateMustBeLater("start_date", "end_date"),
      ]
    );

    ;
    this.formLoading = false;
  }

  get acknowledgement() {
    return this.clientForm.get("acknowledgement");
  }

  get default_password() {
    return this.clientForm.get("default_password");
  }

  get engagement_description() {
    return this.clientForm.get("engagement_description");
  }

  get description() {
    return this.clientForm.get("description");
  }

  get client_domain() {
    return this.clientForm.get("client_domain");
  }

  get client_name() {
    return this.clientForm.get("client_name");
  }

  get end_time() {
    return this.clientForm.get("end_time");
  }

  get end_date() {
    return this.clientForm.get("end_date");
  }

  get start_time() {
    return this.clientForm.get("start_time");
  }

  get start_date() {
    return this.clientForm.get("start_date");
  }

  public onInputFieldChange(event, type) {
    let dateValue = this.clientForm.controls.start_date.value;

    if (type === "end") {
      dateValue = this.clientForm.controls.end_date.value;
    }

    ;

    this.timeTracker[type] = moment(dateValue).format("X");
    if (type === "start") {
      let yesterday = moment(dateValue).subtract(1, "days").format("M-D-YYYY");
      let parseYesterday = yesterday.split("-");
      this.myEndDatePickerOptions = {
        ...this.myEndDatePickerOptions,
        disableUntil: {
          year: parseInt(parseYesterday[2]),
          month: parseInt(parseYesterday[0]),
          day: parseInt(parseYesterday[1]),
        },
      };
    }
  }

  public async clickActionButton() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let startTime = moment(this.processTime(this.clientForm.value.start_time), [
      "h:mm A",
    ]);

    let startDateFormatted =
      moment(this.clientForm.value.start_date).format("ddd, D MMM YYYY") +
      " " +
      this.checkAfternoons(this.clientForm.value.start_time, startTime) +
      " " +
      this.tz;
    let endTime = moment(this.processTime(this.clientForm.value.end_time), [
      "h:mm A",
    ]);
    let endDateFormatted =
      moment(this.clientForm.value.end_date).format("ddd, D MMM YYYY") +
      " " +
      this.checkAfternoons(this.clientForm.value.end_time, endTime) +
      " " +
      this.tz;

    const data = {
      name: this.clientForm.value.client_name,
      domain: this.clientForm.value.client_domain,
      description: this.clientForm.value.description,
      password: this.clientForm.value.default_password,
      engagement: {
        description: this.clientForm.value.engagement_description,
        start_date: startDateFormatted,
        end_date: endDateFormatted,
      },
    };

    let link = "/team";
    let requestType = "post";
    let action = "added";
    if (this.action == "update") {
      link = link + "/" + this.client.id + "/update";
      requestType = "put";
      action = "updated";
    }

    let newClient = await this.postNewClientRequest(link, requestType, data, getOptions).catch(
      (error) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = error.error.error;
      }
    );

    let updateClientMeta = await this.addClientMeta(newClient.team.id, getOptions).catch(
      (error) => {
        this.msgs.processingMsg = "";
        this.btnLabel.retry = "Retry";
        this.msgs.errorMsg = error.error.error;
      }
    );

    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.msgs.statusMsg = "Client successfully " + action;
    let outgoingData = {
      action: this.action,
      client: newClient.team,
    };
    this.outgoing.next(outgoingData);
  }

  private postNewClientRequest(link, requestType, data, getOptions) {
    let manageClient = this._xrPlatformRestService.restfulAPIQuery(
      link,
      requestType,
      data,
      getOptions
    );

    return manageClient.toPromise();
  }

  private addClientMeta(teamID, getOptions) {
    let data = {
      avatar_type: 1
    };

    let updateAvatarType = this._xrPlatformRestService.restfulAPIQuery(
      "/team/" + teamID + "/update-meta",
      "put",
      data,
      getOptions
    );

    return updateAvatarType.toPromise();
  }

  private processTime(time) {
    if (time.indexOf("AM") !== -1) {
      time = time.replace(/AM/, "");
      time += " AM";
    } else {
      time = time.replace(/PM/, "");
      time += " PM";
    }

    return time;
  }

  private checkAfternoons(incomingTime, momentTime) {
    //setup time as normal
    let outGoingtime = momentTime.format("HH:mm:ss");

    //check to make sure formatted value is really in the afternoon
    if (incomingTime.indexOf("PM") !== -1) {
      var hour = momentTime.hour();

      //uh oh something happened
      if (hour < 12) outGoingtime = momentTime.add(12, "h").format("HH:mm:ss");
    }

    return outGoingtime;
  }

  public openPasswordModal(client) {
    this.modalOptions.data = {
      client: client,
    };

    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "share-modal-container",
    };

    this.passwordFrame = this.modalService.show(
      EditClientPasswordModalComponent,
      this.modalOptions
    );
  }

  public resetForm() {
    this.clientForm.reset();
    this.formState = "active";
    this.clientForm.controls["start_time"].setValue(
      moment().format("hh:mm A") + " " + this.tz
    );
    this.clientForm.controls["start_date"].setValue(new Date());
    this.datePickerEnd.clearDate();
    this.clearStatus();
  }

  public clearStatus() {
    this.msgs.statusMsg = "";
    this.msgs.processingMsg = "";
    this.msgs.errorMsg = "";
    this.formState = "active";
  }

  public handleIncomingAction(action) {
    ;

    switch (action) {
      case "close-modal":
        this.clientFrame.hide();
        break;
      case "reset-form":
        this.resetForm();
        break;
      case "clear-status":
        this.clearStatus();
        break;
    }
  }

  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
