import { ExperiencesAllComponent } from "./modules/experience-management/experiences-all/experiences-all.component";
import { EventsBaseComponent } from "./modules/event-management/events-base/events-base.component";
import { ExperiencesSingleComponent } from "./modules/experience-management/experiences-single/experiences-single.component";
import { SiteSettingsComponent } from "./modules/super-admin/site-settings/site-settings.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MetricsLandingComponent } from "./modules/metrics/metrics-landing/metrics-landing.component";
import { LoginComponent } from "./modules/authentication/login/login.component";

import { NeedAuthGuardService } from "./services/utilities/need-auth-guard.service";
import { ClientBaseComponent } from "./modules/pages/client-base/client-base.component";
import { PasswordResetComponent } from "./modules/authentication/password-reset/password-reset.component";
import { UsersCsvUploadComponent } from "./modules/super-admin/users-csv-upload/users-csv-upload.component";
import { UserMetaInventorySessionResultsComponent } from "./modules/user-management/user-meta-display/displays/user-meta-inventory-session-results/user-meta-inventory-session-results.component";
import { EntityManagementComponent } from "./modules/super-admin/entity-management/entity-management.component";
import { SessionBreakdownComponent } from "./modules/metrics/metrics-interactive/session-breakdown/session-breakdown.component";
import { InventoryManagementComponent } from "./modules/super-admin/inventory-management/inventory-management.component";
import { UniversalSettingsComponent } from "./modules/universal/universal-settings/universal-settings.component";
import { MyUsersComponent } from "./modules/user-management/my-users/my-users.component";
import { VendorManagementComponent } from "./modules/super-admin/vendor-management/vendor-management.component";
import { MediaManagerBaseComponent } from "./modules/media-management/media-manager-base/media-manager-base.component";
import { ClientListComponent } from "./modules/super-admin/client-list/client-list.component";
import { UserCatalogComponent } from "./modules/user-management/user-catalog/user-catalog.component";
import { WelcomePageComponent } from "./modules/pages/welcome-page/welcome-page.component";
import { VersionSupportComponent } from "./modules/universal/version-support/version-support.component";
import { MetricsPerformanceComponent } from "./modules/metrics/metrics-performance/metrics-performance.component";
import { AuthoringSkillsComponent } from "./modules/authoring/authoring-skills/authoring-skills.component";
import { AuthoringSkillActionsComponent } from "./modules/authoring/authoring-skill-actions/authoring-skill-actions.component";
import { MetricsReadquestComponent } from './modules/metrics/metrics-readquest/metrics-readquest.component';

import { environment } from '../environments/environment';
import { ReadquestAnalyticsDrillDownSingleUserComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-user/readquest-analytics-drill-down-single-user.component";
import { ReadquestAnalyticsDrillDownSingleUserSkillComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-skill/readquest-analytics-drill-down-single-user-skill.component";
import { ReadquestAnalyticsDrillDownSingleSkillComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-skill/readquest-analytics-drill-down-single-skill.component";
import { MetricsReadquestSkillsComponent } from "./modules/metrics/metrics-readquest/drilldown/metrics-readquest-skills/metrics-readquest-skills.component";
import { ReadquestAnalayticsDrillDownSingleUserSkillPhonemeAccuracyComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analaytics-drill-down-single-user-skill-phoneme-accuracy/readquest-analaytics-drill-down-single-user-skill-phoneme-accuracy.component";
import { ReadquestAnalyticsDrillDownSingleUserPhonemeAccuracyComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-phoneme-accuracy/readquest-analytics-drill-down-single-user-phoneme-accuracy.component";
import { ReadquestAnalyticsDrillDownSingleUserWordAccuracyComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-word-accuracy/readquest-analytics-drill-down-single-user-word-accuracy.component";
import { ReadquestAnalyticsDrillDownSingleUserSkillWordAccuracyComponent } from "./modules/metrics/metrics-readquest/drilldown/readquest-analytics-drill-down-single-user-skill-word-accuracy/readquest-analytics-drill-down-single-user-skill-word-accuracy.component";
import { MetricsAiaComponent } from "./modules/metrics/metrics-aia/metrics-aia.component";
import { MetricsAiaByEventComponent } from "./modules/metrics/metrics-aia/drilldown/metrics-aia-by-event/metrics-aia-by-event.component";
import { MetricsAiaByUserComponent } from "./modules/metrics/metrics-aia/drilldown/metrics-aia-by-user/metrics-aia-by-user.component";
import { MetricsAiaSingleSessionComponent } from "./modules/metrics/metrics-aia/drilldown/metrics-aia-single-session/metrics-aia-single-session.component";
import { MetricsAiaAssessmentComponent } from "./modules/metrics/metrics-aia/drilldown/metrics-aia-assessment/metrics-aia-assessment.component";
import { SetupAccountComponent } from "./modules/authentication/setup-account/setup-account.component";
import { RequestNewInviteComponent } from "./modules/authentication/request-new-invite/request-new-invite.component";
import { ForgotPasswordComponent } from "./modules/authentication/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./modules/authentication/reset-password/reset-password.component";

let routes: Routes = [
  {
    path: "",
    component: ClientListComponent,
    canActivate: [NeedAuthGuardService],
    data: { roles: ["super-admin"] },
  },
  {
    path: "universal-settings",
    component: UniversalSettingsComponent,
    canActivate: [NeedAuthGuardService],
    data: { roles: ["super-admin"] },
  },
  {
    path: "version-support",
    component: VersionSupportComponent,
    canActivate: [NeedAuthGuardService],
    data: {
      title: "Version Support",
      action: "add_version",
      actionType: "add",
      actionTitle: "Add Version",
      roles: ["super-admin"],
    },
  },
  {
    path: "client-list",
    component: ClientListComponent,
    canActivate: [NeedAuthGuardService],
    data: { roles: ["super-admin"] },
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "password-reset/:token",
    component: PasswordResetComponent,
  },
  {
    path: "password-reset",
    component: PasswordResetComponent,
  },
  {
    path: "client/:client_code",
    component: ClientBaseComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent,
      },
      {
        path: "user/reset-password",
        component: ResetPasswordComponent,
      },
      {
        path: "setup-account",
        component: SetupAccountComponent,
      },
      {
        path: "request-new-invite",
        component: RequestNewInviteComponent,
      },
      {
        path: "",
        canActivate: [NeedAuthGuardService],
        canActivateChild: [NeedAuthGuardService],
        children: [
          {
            path: "",
            component: WelcomePageComponent,
            data: {
              title: "Welcome",
              username: true,
              action: "manage_account_details",
              actionType: "manage",
              actionTitle: "Manage Account Details",
            },
          },
          {
            path: "settings",
            component: SiteSettingsComponent,
            data: { roles: ["super-admin"] },
          },
          {
            path: "user-csv-upload",
            component: UsersCsvUploadComponent,
            data: { roles: ["super-admin"] },
          },
          {
            path: "entity-management",
            component: EntityManagementComponent,
            data: { roles: ["super-admin"] },
          },
          {
            path: "vendor-management",
            component: VendorManagementComponent,
            data: { roles: ["super-admin"] },
          },
          {
            path: "inventory-management",
            component: InventoryManagementComponent,
            data: { roles: ["super-admin"] },
          },
          {
            path: "users",
            children: [
              {
                path: "",
                component: UserCatalogComponent,
                data: {
                  title: "User Management",
                  actions: [
                    {
                      action: "invite_users",
                      type: "add",
                      title: "Invite Users",
                      roles: ["super-admin", "admin"],
                    },
                    {
                      action: "add_users",
                      type: "add",
                      title: "Add Generic Users",
                      roles: ["super-admin"],
                    },
                  ],
                  roles: ["super-admin", "admin"],
                },
              },
              {
                path: "groups",
                component: UserCatalogComponent,
                data: { roles: ["super-admin", "admin"] },
              },
              {
                path: "my-users",
                component: MyUsersComponent,
                data: { roles: ["super-admin", "admin", "curator"] },
              },
              {
                path: "meta/inventory-session-results/historic",
                redirectTo: "meta/inventory-session-results",
                pathMatch: "full",
              },
              {
                path: "meta/inventory-session-results",
                component: UserMetaInventorySessionResultsComponent,
                data: {
                  roles: [
                    "super-admin",
                    "admin",
                    "viewer",
                    "curator",
                    "participant",
                  ],
                },
              },
            ],
          },
          {
            path: "experiences",
            component: ExperiencesAllComponent,
            data: { roles: ["super-admin", "admin", "curator"] },
          },
          {
            path: "experiences/:experience_id",
            component: ExperiencesSingleComponent,
            data: { roles: ["super-admin", "admin", "curator"] },
          },
          {
            path: "all-legacy-events",
            component: EventsBaseComponent,
            data: { roles: ["super-admin", "admin", "curator"] },
          },
          {
            path: "legacy-events",
            component: EventsBaseComponent,
            data: { roles: ["super-admin", "admin", "curator"] },
          },
          {
            path: "all-events",
            redirectTo: "meetings",
            pathMatch: "full",
          },
          {
            path: "meetings",
            children: [
              {
                path: "",
                component: EventsBaseComponent,
                data: {
                  title: "determine",
                  action: "add_meeting",
                  actionType: "add",
                  actionTitle: "determine",
                  roles: ["super-admin", "admin", "curator"],
                },
              },
              {
                path: ":event_id",
                component: EventsBaseComponent,
                data: {
                  title: "determine",
                  action: "add_meeting",
                  actionType: "add",
                  actionTitle: "determine",
                  roles: ["super-admin", "admin", "curator"],
                },
              },
            ],
          },
          {
            path: "events",
            component: EventsBaseComponent,
            data: { roles: ["super-admin", "admin", "curator"] },
          },
          {
            path: "metrics",
            redirectTo: "analytics",
            pathMatch: "full",
          },
          {
            path: "metrics/:path",
            redirectTo: "analytics/:path",
          },
          {
            path: "metrics/:path/:subpath",
            redirectTo: "analytics/:path/:subpath",
          },
          {
            path: "metrics/:path/:subpath/:id",
            redirectTo: "analytics/:path/:subpath/:id",
          },
          {
            path: "analytics",
            children: [
              {
                path: "",
                component: MetricsLandingComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "Interactive Analytics",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/users",
                component: MetricsReadquestComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "RQ User Analytics",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/skills",
                component: MetricsReadquestSkillsComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "RQ Skill Analytics",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id",
                component: ReadquestAnalyticsDrillDownSingleUserComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id/phoneme/:phoneme_id",
                component: ReadquestAnalyticsDrillDownSingleUserPhonemeAccuracyComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id/word/:word_id",
                component: ReadquestAnalyticsDrillDownSingleUserWordAccuracyComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id/skill/:skill_id",
                component: ReadquestAnalyticsDrillDownSingleUserSkillComponent,
                data: {
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id/skill/:skill_id/phoneme-accuracy",
                component: ReadquestAnalayticsDrillDownSingleUserSkillPhonemeAccuracyComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine", roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/user/:user_id/skill/:skill_id/word-accuracy",
                component: ReadquestAnalyticsDrillDownSingleUserSkillWordAccuracyComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "readquest/skill/:skill_id",
                component: ReadquestAnalyticsDrillDownSingleSkillComponent,
                data: {
                  action: "download_csv",
                  actionType: "download",
                  actionTitle: "Download CSV",
                  title: "determine",
                  roles: ["super-admin", "admin"]
                },
              },
              {
                path: "aia",
                component: MetricsAiaComponent,
                data: { title: "AI Reporting Dashboard", roles: ["super-admin", "admin"] },
              },
              {
                path: "aia/scenario/:event_id",
                component: MetricsAiaByEventComponent,
                data: { title: "AIA Reporting Dashboard", roles: ["super-admin", "admin"] },
              },
              {
                path: "aia/user/:user_id",
                component: MetricsAiaByUserComponent,
                data: { title: "AIA Reporting Dashboard", roles: ["super-admin", "admin"] },
              },
              {
                path: "aia/session/:session_id",
                component: MetricsAiaSingleSessionComponent,
                data: { title: "AIA Reporting Dashboard", roles: ["super-admin", "admin"] },
              },
              {
                path: "aia/session/:session_id/assessment/:assessment_target",
                component: MetricsAiaAssessmentComponent,
                data: { title: "AIA Reporting Dashboard", roles: ["super-admin", "admin"] },
              },
              {
                path: ":session_id",
                component: SessionBreakdownComponent,
                data: { title: "Session Breakdown", roles: ["super-admin", "admin"] },
              }
            ]
          },
          {
            path: "performance-metrics",
            component: MetricsPerformanceComponent,
            data: { roles: ["super-admin", "admin"] },
          },
          {
            path: "media-library",
            component: MediaManagerBaseComponent,
            data: {
              roles: ["super-admin", "admin", "curator", "participant", "dataview", "viewer",],
            },
            children: [
              {
                path: "",
                redirectTo: "all-media",
                pathMatch: "full",
              },
              {
                path: "personal-media",
                redirectTo: "all-media",
                pathMatch: "full",
              },
              {
                path: "all-media",
                component: MediaManagerBaseComponent,
                data: {
                  title: "Media Library",
                  actions: [
                    {
                      action: "upload_media",
                      type: "uploadMedia",
                      title: "Upload Media",
                      roles: [
                        "super-admin",
                        "admin",
                        "curator",
                        "participant",
                        "dataview",
                      ],
                    },
                  ],
                  roles: [
                    "super-admin",
                    "admin",
                    "curator",
                    "participant",
                    "dataview",
                  ],
                },
              },
            ],
          },
          {
            path: "authoring",
            children: [
              {
                path: "",
                redirectTo: "skills",
                pathMatch: "full"
              },
              {
                path: "skills",
                component: AuthoringSkillsComponent,
                data: {
                  title: "Skills Entry",
                  action: "add_skill",
                  actionType: "add",
                  actionTitle: "Add Skill",
                  roles: ["super-admin"],
                },
              },
              {
                path: "skills/:skill_id",
                component: AuthoringSkillActionsComponent,
                data: {
                  title: "Loading Skill",
                  requiredMsg: true,
                  loadingAni: true,
                  action: "push_to_sheets",
                  actionType: "push",
                  actionTitle: "Push to Sheets",
                  roles: ["super-admin"]
                },
              },
            ],
          }
        ],
      },
    ]
  }
];

console.log("includeSpecialComponent", environment.includeSpecialComponent);

if (environment.includeSpecialComponent) {

  console.log("includeSpecialComponent is true, adding special component");

  // Conditionally import the component using require (CommonJS)
  const ReadquestAnalyticsHighlevelComponent = require('./modules/dev-only/readquest-analytics-highlevel/readquest-analytics-highlevel.component').ReadquestAnalyticsHighlevelComponent;

  console.log("ReadquestAnalyticsHighlevelComponent", ReadquestAnalyticsHighlevelComponent);

  // Add the dev-only route
  routes = routes.map(route => {
    if (route.path === 'client/:client_code') {
      route.children.push({
        path: "readquest-analytics-highlevel",
        component: ReadquestAnalyticsHighlevelComponent,
        data: { title: "RQ HL Analytics", roles: ["super-admin"] },
      });

      route.children.push({
        path: "readquest-analytics-highlevel/:legacy_stage",
        component: ReadquestAnalyticsHighlevelComponent,
        data: { title: "RQ HL Analytics", roles: ["super-admin"] },
      });
    }
    return route;
  });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
