<!-- Modal Content -->
<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>{{ title }}</span>
    </h4>
    <button
      *ngIf="isClean || action === 'delete'"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean && action !== 'delete'"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <!-- content -->
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div
        *ngIf="formLoading && action !== 'delete'"
        class="loading-screen d-flex align-items-center h-100 w-100 justify-content-center"
      >
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="action === 'delete'">
          <div class="col-12">
            <p class="font-16">
              Delete the {{ labels.event.singular | titlecase }}
              {{ targetEvent.event_name }}?
            </p>
          </div>
        </div>
        <form
          *ngIf="!formLoading && action !== 'delete'"
          [formGroup]="manageEventForm"
        >
          <div *ngIf="!viewOnly.date">
            <div class="row p-0">
              <div class="col-12">
                <p>
                  <span class="action-msg-small">Required*</span>
                </p>
              </div>
            </div>

            <div class="row details-row form-split">
              <!--class name-->
              <div class="col-6">
                <div class="row p-0">
                  <div class="col-12 event-name">
                    <div class="md-form mb-1">
                      <input
                        type="text"
                        id="eventTitle"
                        class="form-control"
                        formControlName="event_title"
                        mdbInput
                        mdbValidate
                      />
                      <label
                        class="active-label active"
                        for="eventTitle"
                        [ngClass]="labelClass.event_title"
                        >* {{ labels.event.singular | titlecase }} Name</label
                      >
                      <mdb-error
                        *ngIf="
                          event_title.invalid &&
                          (event_title.dirty || event_title.touched)
                        "
                      >
                        Please enter an {{ labels.event.singular }} name
                      </mdb-error>
                    </div>
                  </div>
                </div>
                <div class="row dates">
                  <div class="col-3">
                    <!--start start date-->
                    <div class="md-form">
                      <mdb-date-picker
                        #datePickerStart
                        class="start-picker"
                        name="startDate"
                        id="startDate"
                        [options]="myDatePickerOptions"
                        label="* Start"
                        [inline]="true"
                        formControlName="startDate"
                        (dateChanged)="onDateChanged($event, 'start')"
                        required
                      ></mdb-date-picker>
                    </div>
                    <!--end start date-->
                  </div>
                  <div class="col-2">
                    <!--start start time-->
                    <div class="md-form">
                      <mdb-select-2 formControlName="startTime">
                        <mdb-select-option
                          *ngFor="let option of timeSelect"
                          [value]="option.value"
                          >{{ option.label }}</mdb-select-option
                        >
                      </mdb-select-2>
                    </div>
                    <!--end start time-->
                  </div>
                  <div class="col-1 text">
                    <span>to</span>
                  </div>
                  <div class="col-2">
                    <!--start end time-->
                    <div class="md-form">
                      <mdb-select-2 label="* End" formControlName="endTime">
                        <mdb-select-option
                          *ngFor="let option of timeSelect"
                          [value]="option.value"
                          >{{ option.label }}</mdb-select-option
                        >
                      </mdb-select-2>
                    </div>
                    <!--end end time-->
                  </div>
                  <div class="col-3">
                    <!--start end date-->
                    <div class="md-form">
                      <mdb-date-picker
                        #datePickerEnd
                        class="end-picker"
                        name="endDate"
                        id="endDate"
                        [options]="myEndDatePickerOptions"
                        [inline]="true"
                        formControlName="endDate"
                        (dateChanged)="onDateChanged($event, 'end')"
                        required
                      ></mdb-date-picker>
                    </div>
                    <!--end end date-->
                  </div>
                  <div class="col-1 text">
                    <span>{{ tz }}</span>
                  </div>
                  <mdb-error
                    class="static-error error-hiding"
                    [ngClass]="{
                      'fade-in':
                        (startDate.invalid &&
                          (startDate.dirty || startDate.touched)) ||
                        (endDate.invalid &&
                          !endDate.errors.dateMustBeLater &&
                          (endDate.dirty || endDate.touched))
                    }"
                  >
                    Please enter a date
                  </mdb-error>
                  <mdb-error
                    class="static-error error-hiding"
                    [ngClass]="{
                      'fade-in':
                        endTime.invalid && endTime.errors.timeMustBeLater
                    }"
                  >
                    {{ labels.endTime.singular | capitalizeFirst : [] }} must be
                    later than the
                    {{ labels.startTime.singular }}
                  </mdb-error>
                  <mdb-error
                    class="static-error error-hiding"
                    [ngClass]="{
                      'fade-in':
                        endDate.invalid && endDate.errors.dateMustBeLater
                    }"
                  >
                    {{ labels.endDate.singular | titlecase }} must be on the
                    same day or later than the
                    {{ labels.startDate.singular | titlecase }}.
                  </mdb-error>
                </div>
              </div>
              <!-- end class name -->

              <!-- event public status -->
              <div class="col-6">
                <div class="form-radio-buttons access-details">
                  <p class="m-0">
                    <span class="action-msg-small"
                      >* {{ labels.event.singular | titlecase }} Access (cannot
                      be changed after {{ labels.event.singular }} is
                      saved)</span
                    >
                  </p>
                  <div class="access-row top">
                    <div class="d-flex">
                      <div
                        class="form-check form-check-inline"
                        [ngClass]="{
                          selected: showPrivateTypes === true,
                          disabled: action !== 'add'
                        }"
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          id="publicStatusFalse"
                          [value]="false"
                          formControlName="is_public"
                          name="is_public"
                        />
                        <label class="form-check-label" for="publicStatusFalse"
                          >Private</label
                        >
                      </div>
                      <div
                        class="form-check form-check-inline"
                        [ngClass]="{
                          selected: showPrivateTypes === false,
                          disabled: action !== 'add'
                        }"
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          id="publicStatusTrue"
                          [value]="true"
                          formControlName="is_public"
                          name="is_public"
                        />
                        <label class="form-check-label" for="publicStatusTrue"
                          >Public</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="access-row bottom">
                    <div
                      class="d-flex align-items-start"
                      *ngIf="showPrivateTypes === true"
                    >
                      <div
                        class="form-check form-check-inline access-type"
                        [ngClass]="{
                          selected: selectedPrivateType === 'has_invitees',
                          disabled: action !== 'add'
                        }"
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          id="privateTypeHasInvitees"
                          value="has_invitees"
                          formControlName="private_type"
                          name="private_type"
                        />
                        <label
                          class="form-check-label"
                          for="privateTypeHasInvitees"
                          >By Invite</label
                        >
                      </div>
                      <div
                        class="form-check form-check-inline"
                        [ngClass]="{
                          selected:
                            selectedPrivateType === 'is_access_code_required',
                          disabled: action !== 'add'
                        }"
                      >
                        <input
                          type="radio"
                          class="form-check-input"
                          id="privateTypeIsAccessCodeRequired"
                          value="is_access_code_required"
                          formControlName="private_type"
                          name="private_type"
                        />
                        <label
                          class="form-check-label"
                          for="privateTypeIsAccessCodeRequired"
                          >By Access Code</label
                        >
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center"
                      *ngIf="
                        showPrivateTypes === true &&
                        selectedPrivateType === 'has_invitees'
                      "
                    >
                      <button
                        class="theme-modal-button btn"
                        type="button"
                        mdbBtnp
                        mdbWavesEffect
                        (click)="openEventRoster('invitee')"
                      >
                        <fa-icon [icon]="faCirclePlus"></fa-icon>
                        {{ action === "update" ? "Update" : "Add" }}
                        Users/Groups
                      </button>
                      <span class="list-start"
                        >{{ labels.user.plural | titlecase }}:
                        {{ inviteeUsers.length }}</span
                      >
                      <span
                        >{{ labels.userGroup.plural | titlecase }}:
                        {{ inviteeGroups.length }}</span
                      >
                    </div>
                    <div
                      class="d-flex"
                      *ngIf="
                        showPrivateTypes === true &&
                        selectedPrivateType === 'is_access_code_required'
                      "
                    >
                      <p *ngIf="action === 'add'">
                        Access code will be automatically generated on save.
                      </p>
                      <div
                        *ngIf="action === 'update'"
                        class="input-group mr-sm-2"
                      >
                        <input
                          mdbInput
                          [type]="showAccessCode ? 'text' : 'password'"
                          class="form-control py-0"
                          id="inlineFormInputGroupUsername2"
                          [value]="targetEvent.access_code"
                          [ngClass]="{ 'code-hidden': !showAccessCode }"
                        />
                        <div class="input-group-append cursor-pointer">
                          <div
                            class="input-group-text"
                            (click)="toggleAccessCode()"
                          >
                            <fa-icon
                              *ngIf="showAccessCode"
                              [icon]="faEyeSlash"
                            ></fa-icon>
                            <fa-icon
                              *ngIf="!showAccessCode"
                              [icon]="faEye"
                            ></fa-icon>
                          </div>
                          <button
                            class="input-group-text input-end"
                            (click)="copyAccessCode(event)"
                          >
                            <fa-icon [icon]="faCopy"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end event public status -->
            </div>

            <div class="row event-properties-row">
              <div class="col-12">
                <div class="grid">
                  <ng-container *ngFor="let prop of teamProps; let i = index">
                    <app-manage-event-part-props
                      *ngIf="
                        !prop.archived &&
                        !props_for_experience_section.includes(prop.props.name)
                      "
                      [prop]="prop"
                      [index]="i"
                      [teamID]="teamID"
                      [teamPropsSelects]="teamPropsSelects"
                      [action]="action"
                      [labels]="labels"
                      [attached]="attached"
                      [targetEvent]="targetEvent"
                      [formGroup]="manageEventForm"
                      [isClean]="isClean"
                      (formGroupChange)="onFormGroupChange($event)"
                      (mediaChanged)="onMediaChanged($event)"
                      (isCleanChange)="onIsCleanChange($event)"
                    ></app-manage-event-part-props>
                  </ng-container>
                </div>
              </div>
            </div>

            <mdb-accordion
              class="custom-icons icons-plus-minus"
              [multiple]="false"
            >
              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head
                  ><span *ngIf="!is_hub">* Manage Experiences</span
                  ><span *ngIf="is_hub"
                    >* Manage Hub</span
                  ></mdb-accordion-item-head
                >
                <mdb-accordion-item-body>
                  <!--core experience properties-->
                  <div class="grid experiences-core-properties">
                    <div class="grid-item">
                      <div class="md-form experience flex-grow-1">
                        <!--experience-->
                        <mdb-select-2
                          placeholder="Choose an {{
                            labels.experience.singular | titlecase
                          }}"
                          label="* Starting {{
                            labels.experience.singular | titlecase
                          }}"
                          formControlName="starting_experience_id"
                          mdbValidate
                        >
                          <mdb-select-option
                            *ngFor="let option of startingExperienceSelects"
                            [value]="option.value"
                            >{{ option.label }}</mdb-select-option
                          >
                        </mdb-select-2>
                        <mdb-error
                          *ngIf="
                            starting_experience_id.invalid &&
                            (starting_experience_id.dirty ||
                              starting_experience_id.touched)
                          "
                        >
                          Please select a starting
                          {{ labels.experience.singular }}
                        </mdb-error>
                      </div>
                    </div>
                    <div class="grid-item">
                      <!-- start number of attendees -->
                      <div class="md-form">
                        <mdb-select-2
                          class="number-of-attendees-prop"
                          placeholder="Select Attendee Capacity"
                          label="* Attendee Capacity"
                          formControlName="number_of_attendees_prop"
                        >
                          <mdb-select-option
                            *ngFor="let option of attendeeSelect"
                            [value]="option.value"
                            >{{ option.label }}</mdb-select-option
                          >
                        </mdb-select-2>
                      </div>
                      <!-- end number of attendees -->
                    </div>
                    <div
                      class="grid-item checkbox-wrapper"
                      *ngIf="singleExperienceSet"
                    >
                      <div class="md-form">
                        <mdb-checkbox
                          formControlName="single_user_experience_prop"
                        >
                          Single User Experience?
                        </mdb-checkbox>
                      </div>
                    </div>
                  </div>
                  <!--end core experience properties-->

                  <app-manage-event-part-experiences
                    *ngIf="!is_hub && !is_aia"
                    formControlName="experiences"
                    [experiences]="experiences"
                    [experiencesFormArray]="experiencesFormArray"
                    [experienceFieldsGroups]="experienceFieldsGroups"
                    [experiencesSelectCurrent]="experiencesSelectCurrent"
                    [experiencesSelect]="experiencesSelect"
                    [experiencesSelectBase]="experiencesSelectBase"
                    [startingExperienceSelects]="startingExperienceSelects"
                    [attendeePropID]="attendeePropID"
                    [starting_experience_id]="starting_experience_id"
                    [manageEventForm]="manageEventForm"
                    [targetEvent]="targetEvent"
                    [labels]="labels"
                    [is_hub]="is_hub"
                    [is_aia]="is_aia"
                    [isClean]="isClean"
                    [teamProps]="teamProps"
                    [teamPropOptions]="teamPropOptions"
                    [preSelected]="preSelected"
                    [currentAttendeeValue]="currentAttendeeValue"
                    [attendeeSelect]="attendeeSelect"
                    (dataChanges)="onDataChanges($event)"
                  ></app-manage-event-part-experiences>

                  <div
                    *ngIf="is_aia && !loading_aia"
                    class="row experiences experiences-aia"
                  >
                    <div class="col-md-12 aia-files">
                      <p class="font-16">AI Experience:</p>
                      <ul class="list-group">
                        <li class="aia-fields-wrapper list-group-item">
                          <div class="grid">
                            <div class="grid-item">
                              <!-- start ai users -->
                              <div class="md-form" *ngIf="ai_users.length">
                                <mdb-select-2
                                  class="ai_user"
                                  placeholder="Select AI User"
                                  label="AI User"
                                  formControlName="ai_user"
                                >
                                  <mdb-select-option
                                    *ngFor="let option of ai_users"
                                    [value]="option.value"
                                    >{{ option.label }}</mdb-select-option
                                  >
                                </mdb-select-2>
                              </div>
                              <div class="md-form" *ngIf="!ai_users.length">
                                <p style="display: block">
                                  No users have
                                  <a
                                    href="https://ai.foretellreality.com/"
                                    target="_blank"
                                    >configured an AI Experience using the
                                    authoring tool.</a
                                  >
                                  Please add an AI Experience using the
                                  authoring tool and then return here to select
                                  that Experience.
                                </p>
                              </div>
                              <!-- end ai users -->
                            </div>
                            <div
                              class="grid-item"
                              *ngIf="manageEventForm.controls['ai_user'].value"
                            >
                              <!-- start number of attendees -->
                              <div class="md-form">
                                <mdb-select-2
                                  class="ai_experience_file"
                                  placeholder="Select File"
                                  label="AI Experience File"
                                  formControlName="aia_ai_experience"
                                >
                                  <mdb-select-option
                                    *ngFor="let option of ai_experience_files"
                                    [value]="option.value"
                                    >{{ option.label }}</mdb-select-option
                                  >
                                </mdb-select-2>
                              </div>
                              <!-- end number of attendees -->
                            </div>
                          </div>
                        </li>
                        <li
                          class="aia-fields-wrapper list-group-item"
                          *ngIf="manageEventForm.controls['ai_user'].value"
                        >
                          <div class="grid">
                            <div class="grid-item checkbox-wrapper">
                              <div class="md-form">
                                <mdb-checkbox formControlName="aia_use_latest">
                                  Use Latest Iteration?
                                </mdb-checkbox>
                              </div>
                            </div>
                            <div
                              class="grid-item"
                              *ngIf="
                                !manageEventForm.controls['aia_use_latest']
                                  .value
                              "
                            >
                              <!-- start number of attendees -->
                              <div class="md-form">
                                <mdb-select-2
                                  class="ai_iteration"
                                  placeholder="Select Iteration"
                                  label="AI File Iteration"
                                  formControlName="aia_iteration"
                                >
                                  <mdb-select-option
                                    *ngFor="
                                      let option of ai_experience_file_iterations
                                    "
                                    [value]="option.value"
                                    >{{ option.label }}</mdb-select-option
                                  >
                                </mdb-select-2>
                              </div>
                              <!-- end number of attendees -->
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!--start hub experiences-->
                  <div *ngIf="is_hub" class="row experiences experiences-hub">
                    <div class="col-md-12 hubs">
                      <p class="font-16">Manage Hub Zones:</p>
                      <div
                        class="zones-fields-wrapper"
                        *ngFor="
                          let zoneFields of zonesFieldsGroups;
                          let i = index
                        "
                        [ngClass]="{ striped: i % 2 === 0 }"
                      >
                        <div class="zone-title">
                          <p>Zone {{ i + 1 }}</p>
                        </div>
                        <div [formGroup]="zoneFields">
                          <div class="grid">
                            <div class="md-form grid-item">
                              <input
                                type="text"
                                [id]="'label' + i"
                                class="form-control"
                                formControlName="zone_label"
                                mdbInput
                                placeholder="Enter Zone Name"
                              />
                              <label
                                class="active-label active"
                                [for]="'label' + i"
                                >Zone Name</label
                              >
                            </div>
                            <div
                              class="md-form experience flex-grow-1 md-form grid-item"
                            >
                              <!--experience-->
                              <mdb-select-2
                                [allowClear]="true"
                                placeholder="Choose an {{
                                  labels.experience.singular | titlecase
                                }}"
                                label="{{
                                  labels.experience.singular | titlecase
                                }} Name"
                                formControlName="zone_experience_id"
                              >
                                <mdb-select-option
                                  *ngFor="let option of zonesSelect[i]"
                                  [value]="option.value"
                                  >{{ option.label }}</mdb-select-option
                                >
                              </mdb-select-2>
                            </div>
                          </div>
                          <div
                            mdbCollapse
                            [isCollapsed]="
                              !zoneFields.controls['has_extras'].value
                            "
                          >
                            <div class="d-flex extras-actions">
                              <div
                                class="extras-action hub"
                                *ngIf="zoneFields.controls['has_hub'].value"
                              >
                                <button
                                  class="theme-modal-button btn"
                                  type="button"
                                  mdbBtnp
                                  mdbWavesEffect
                                  [disabled]="formLoading"
                                  (click)="openZoneHubModal(i)"
                                >
                                  Manage Zones for
                                  <!-- {{
                                    getExperienceByID(
                                      zonesFieldsGroups[i].controls
                                        .zone_experience_id.value
                                    )[0].experience.label
                                  }} -->
                                </button>
                              </div>
                              <ng-template
                                [ngIf]="getZoneExtrasFieldsGroups(i).length"
                              >
                                <ng-template
                                  ngFor
                                  let-extras_i="index"
                                  let-zoneExtraFields
                                  [ngForOf]="getZoneExtrasFieldsGroups(i)"
                                >
                                  <div
                                    class="extras-action extra media-360"
                                    [formGroup]="zoneExtraFields"
                                  >
                                    <ng-template
                                      [ngIf]="
                                        zoneExtraFields.controls.attribute_type
                                          .value === 'asset_single'
                                      "
                                    >
                                      <button
                                        class="theme-modal-button btn"
                                        type="button"
                                        mdbBtnp
                                        mdbWavesEffect
                                        (click)="
                                          openSelectMediaModal(
                                            extras_i,
                                            i,
                                            zoneExtraFields.controls
                                              .attribute_subtype.value,
                                            zoneExtraFields.controls
                                              .attribute_full_data.value
                                          )
                                        "
                                      >
                                        <fa-icon
                                          [icon]="faPlusSquare"
                                        ></fa-icon>
                                        Select
                                        {{
                                          zoneExtraFields.controls
                                            .attribute_label.value
                                        }}
                                      </button>
                                      <div
                                        *ngIf="
                                          checkThumbnail(
                                            zoneExtraFields.controls
                                              .attribute_full_data.value
                                          ) !== null
                                        "
                                        [ngStyle]="{
                                          'background-image':
                                            'url(' +
                                            checkThumbnail(
                                              zoneExtraFields.controls
                                                .attribute_full_data.value
                                            ) +
                                            ')'
                                        }"
                                        class="thumbnail-container"
                                      ></div>
                                      <div
                                        *ngIf="
                                          checkThumbnail(
                                            zoneExtraFields.controls
                                              .attribute_full_data.value
                                          ) === null
                                        "
                                        class="thumbnail-container fallback"
                                      >
                                        <fa-icon [icon]="faImages"></fa-icon>
                                      </div>
                                    </ng-template>
                                  </div>
                                </ng-template>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end hub experiences-->
                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <span
      *ngIf="!formLoading"
      id="mainEventAction"
      class="button-wrapper"
      triggers="focus"
      mdbPopoverHeader=""
      [mdbPopover]="
        updateButtonStatus(manageEventForm) && formState !== 'processing'
          ? mainEventAction
          : null
      "
      (shown)="showingWarning()"
    >
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button btn"
        [disabled]="updateButtonStatus(manageEventForm)"
        (click)="scheduleEvent()"
      >
        <span *ngIf="!isClean || action === 'add'">{{ btnLabel.main }}</span>
        <span *ngIf="isClean && action === 'update'"
          >Return to all {{ labels.event.plural }}</span
        >
      </button>
    </span>
    <button
      *ngIf="isClean || action === 'delete'"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean && action !== 'delete'"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>
<ng-template #mainEventAction
  ><div class="custom-pop error-pop">
    <p>Please review issues above.</p>
  </div></ng-template
>
<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <div class="left-arrow"></div>
    <p class="semi-bold">
      Warning! All {{ action === "add" ? "work" : "updates" }} on this
      {{ labels.event.singular }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        (click)="closePopOvers()"
      >
        Back to Edit {{ labels.event.singular }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        (click)="closeModal(true)"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
