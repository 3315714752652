import { Component } from '@angular/core';

@Component({
  selector: 'app-request-new-invite',
  templateUrl: './request-new-invite.component.html',
  styleUrls: ['./request-new-invite.component.scss']
})
export class RequestNewInviteComponent {

}
