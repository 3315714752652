import { CoolLocalStorage } from '@angular-cool/storage';
import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  @ViewChild('passwordComponentWithConfirmation') passwordComponentWithConfirmation: MatPasswordStrengthComponent;
  @ViewChild('passwordToConfirm') passwordToConfirm: MatPasswordStrengthComponent;

  //persistent
  public teamID: number = 0;
  public clientcode: string = "";
  private token: string = null;
  private code: string = null;

  //form vars
  public forgotPasswordForm: UntypedFormGroup;
  public passwordConfirmd: boolean = false;
  public formLoading: boolean = true;
  public isClean: boolean = true;
  public formState: string = "pending";
  public notification: any;
  public preSelected: {
    password: string;
    confirm_password: string;
  } = {
      password: "",
      confirm_password: ""
    }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {

      // Get the client code
      this.clientcode = params.get('client_code');

      // Get the token
      this.token = queryParams.get('token');
      this.code = queryParams.get('code');

      this.retrieveData();

    });
  }

  private async retrieveData() {

    let getteamID = await this.retrieveTeamIDByClientCode().catch((error) => {
      this._notificationService.errorNotification("Error retrieving team ID");
    });

    this.teamID = getteamID["team_id"];

    this.buildForm();

  }

  private retrieveTeamIDByClientCode() {
    //retrieve team ID by client code
    const headers = {
      "Content-Type": "application/json",
    };

    const httpOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      `/team/id/by-code/${this.clientcode}`,
      "get",
      null,
      httpOptions
    ).toPromise();
  }

  private buildForm() {
    this.forgotPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl(this.preSelected.password, Validators.required)
    });

    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.isClean = this.forgotPasswordForm.pristine;
    });

    this.formLoading = false;

    setTimeout(() => {
      this.passwordComponentWithConfirmation.passwordConfirmationFormControl.valueChanges.subscribe(() => {

        console.log("this.passwordComponentWithConfirmation.passwordConfirmationFormControl", this.passwordComponentWithConfirmation.passwordConfirmationFormControl);

        //if the passwordConfirmationFormControl is valid, then we can set the passwordConfirmd to true
        if (this.passwordComponentWithConfirmation.passwordConfirmationFormControl.valid) {
          this.passwordConfirmd = true;
        } else {
          this.passwordConfirmd = false;
        }

      });
    }, 10);
  }

  public triggerValidations() {

    console.log("this.setupForm in triggerValidations", this.forgotPasswordForm);

    Object.keys(this.forgotPasswordForm.controls).forEach(key => {
      const control = this.forgotPasswordForm.get(key);

      console.log("control in triggerValidations", control);

      control.markAsTouched();
      control.markAsDirty();
    });
    this.passwordComponentWithConfirmation.passwordConfirmationFormControl.markAsTouched();
    this.passwordComponentWithConfirmation.passwordConfirmationFormControl.markAsDirty();
  }

  public onStrengthChanged(event) {
    console.log("onStrengthChanged", event);
  }

  public async submitForm() {
    this.formState = "pending";
    this.notification = this._notificationService.savingNotification("Sending password reset email");

    if (this.forgotPasswordForm.valid) {

      const headers = {
        "Content-Type": "application/json",
      };

      const httpOptions = {
        headers: headers,
      };

      let data = {
        password: this.forgotPasswordForm.value.password,
        token: this.token,
        code: this.code
      }

      let response = await this._xrPlatformRestService.restfulAPIQuery(
        `/users/cognito/${this.teamID}/confirm-password`,
        "post",
        data,
        httpOptions
      ).toPromise().catch((error) => {
        this.formState = "pending";
        this._notificationService.clearNotification(this.notification);
        this._notificationService.errorNotification("Error setting password, please try again");
      });

      console.log("response", response);

      if (response && response["status"] == "OK") {
        this.formState = "success";
        this._notificationService.clearNotification(this.notification);
        this._notificationService.successNotification("Password reset! Redirecting to login page...");

        setTimeout(() => {
          this.router.navigate([`/client/${this.clientcode}/login`]);
        }, 3000);
      }

    } else {
      this.formState = "pending";
      this._notificationService.clearNotification(this.notification);
      this._notificationService.errorNotification("Error setting password, please try again");
    }
  }

}
