import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-ag-renderer-score-bar',
  templateUrl: './ag-renderer-score-bar.component.html',
  styleUrls: ['./ag-renderer-score-bar.component.scss']
})
export class AgRendererScoreBarComponent {
  @Input() score: number;
  public scoreType: string = "high";

  constructor() { }

  agInit(params): void {
    this.score = params.value;

    if(this.score <= 50){
      this.scoreType = "low";
    } else if (this.score <= 70){
      this.scoreType = "medium";
    }
  }

}
