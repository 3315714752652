import { CoolLocalStorage } from '@angular-cool/storage';
import { MatPasswordStrengthComponent } from '@angular-material-extensions/password-strength';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { ClientManagementService } from 'src/app/services/utilities/client-management.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { SettingsService } from 'src/app/services/utilities/settings.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './setup-account.component.html',
  styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent {
  @ViewChild('passwordComponentWithConfirmation') passwordComponentWithConfirmation: MatPasswordStrengthComponent;
  @ViewChild('passwordToConfirm') passwordToConfirm: MatPasswordStrengthComponent;

  //persistent
  public teamID: number = 0;
  public domain: string = "superadmin";
  public clientSettings: any;
  public clientURL: string = "";
  private token: string = null;
  private code: string = null;
  public showContent: boolean = false;
  public isToken: boolean = false;
  public isValidToken: boolean = true;
  public clientcode: any = "";

  //form vars
  public setupForm: UntypedFormGroup;
  public passwordConfirmd: boolean = false;
  public formLoading: boolean = true;
  public isClean: boolean = true;
  public formState: string = "pending";
  public notification: any;
  public preSelected: {
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    password: string;
    confirm_password: string;
    terms: boolean;
  } = {
      email: "",
      username: "",
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      terms: false
    }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _clientManagementService: ClientManagementService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {

      // Get the client code
      this.clientcode = params.get('client_code');

      // Get the token
      this.token = queryParams.get('token');
      this.code = queryParams.get('code');

      this.retrieveData();
    });
  }

  private async retrieveData() {

    let getteamID = await this.retrieveTeamIDByClientCode().catch((error) => {
      this._notificationService.errorNotification("Error retrieving team ID");
    });

    this.teamID = getteamID["team_id"];

    this.buildForm();

  }

  private retrieveTeamIDByClientCode() {
    //retrieve team ID by client code
    const headers = {
      "Content-Type": "application/json",
    };

    const httpOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService.restfulAPIQuery(
      `/team/id/by-code/${this.clientcode}`,
      "get",
      null,
      httpOptions
    ).toPromise();
  }

  private buildForm() {
    this.setupForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.preSelected.email, Validators.required),
      username: new UntypedFormControl(this.preSelected.username, Validators.required),
      first_name: new UntypedFormControl(this.preSelected.first_name, Validators.required),
      last_name: new UntypedFormControl(this.preSelected.last_name, Validators.required),
      password: new UntypedFormControl(this.preSelected.password, Validators.required),
      // confirm_password: new UntypedFormControl(this.preSelected.confirm_password, Validators.required),
      terms: new UntypedFormControl(this.preSelected.terms, Validators.requiredTrue),
    });

    this.setupForm.valueChanges.subscribe(() => {
      this.isClean = this.setupForm.pristine;
    });

    this.formLoading = false;

    setTimeout(() => {
      this.passwordComponentWithConfirmation.passwordConfirmationFormControl.valueChanges.subscribe(() => {

        //if the passwordConfirmationFormControl is valid, then we can set the passwordConfirmd to true
        if (this.passwordComponentWithConfirmation.passwordConfirmationFormControl.valid) {
          this.passwordConfirmd = true;
        } else {
          this.passwordConfirmd = false;
        }

      });
    }, 10);
  }

  public triggerValidations() {

    console.log("this.setupForm in triggerValidations", this.setupForm);

    Object.keys(this.setupForm.controls).forEach(key => {
      const control = this.setupForm.get(key);

      console.log("control in triggerValidations", control);

      control.markAsTouched();
      control.markAsDirty();
    });
    this.passwordComponentWithConfirmation.passwordConfirmationFormControl.markAsTouched();
    this.passwordComponentWithConfirmation.passwordConfirmationFormControl.markAsDirty();
  }

  public async setupAccount() {

    let notification = this._notificationService.savingNotification("Setting up account");

    this.formState = "processing";
    console.log("go setup account");

    let submitUsers = await this.submitAccountInformation().catch((error) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.errorNotification("Submission errors, please see below.", 7000);

      this.formState = "pending";
    });

    if (submitUsers && submitUsers["status"] === "OK") {

      this._notificationService.clearNotification(notification);

      this._notificationService.successNotification("Account successfully verified! Redirecting to login page...", 3000);

      setTimeout(() => {
        this.router.navigate([`/client/${this.clientcode}/login`]);
      }, 3000);
    } else if (submitUsers && submitUsers["status"] === "error") {

      this._notificationService.clearNotification(notification);

      this.formState = "pending";

      let errorNotice = this._notificationService.errorNotification("Submission errors, please see below.", 7000);

      let error = submitUsers["error"];

      if (error === "email_mismatch") {
        this.setupForm.controls.email.setErrors({ email_mismatch: true });
      } else if (error === "invalid_temporary_token" || error === "token_expired" || error === "code_expired" || error === "code_mismatch" || error === "issue_confirming_code") {
        this._notificationService.clearNotification(errorNotice);
        errorNotice = this._notificationService.errorNotification("Invalid or expired code, please request a new invite.", 7000);
      } else if (error === "username_exists") {
        this.setupForm.controls.username.setErrors({ username_exists: true });
      }
    }
  }

  private submitAccountInformation() {
    const headers = {
      "Content-Type": "application/json",
    };

    const httpOptions = {
      headers: headers,
    };

    let body = this.setupForm.value;
    body.code = this.code;
    body.token = this.token;

    return this._xrPlatformRestService.restfulAPIQuery(
      `/users/cognito/${this.teamID}/verify-account`,
      "post",
      body,
      httpOptions
    ).toPromise();
  }

  public onStrengthChanged(event) {
    //console.log("onStrengthChanged", event);
  }

  public async requestNewInvite() {
    this.notification = this._notificationService.savingNotification(
      "Requesting new invite"
    );

    let resendNewInvite = await this.sendRequetNewInvite().catch((error) => {
      this._notificationService.clearNotification(this.notification);
      this._notificationService.errorNotification("Error requesting new invite, please try again or contact support.", 12000);
    });

    this._notificationService.clearNotification(this.notification);

    if (resendNewInvite && resendNewInvite["status"] === "OK") {
      this._notificationService.successNotification("A new invite has been sent to your email!");
    }


  }

  private sendRequetNewInvite() {
    const headers = {
      "Content-Type": "application/json",
    };

    const httpOptions = {
      headers: headers,
    };

    let body = {
      code: this.code,
      token: this.token,
    }

    return this._xrPlatformRestService.restfulAPIQuery(
      `/users/cognito/${this.teamID}/resend-confirmation`,
      "post",
      body,
      httpOptions
    ).toPromise();
  }

}
