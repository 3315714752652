import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimInput]'
})
export class TrimInputDirective {
  constructor(
    private elementRef: ElementRef,
    private control: NgControl
  ) {}

  @HostListener('blur')
  onBlur() {
    if (this.control && this.control.value) {
      const value = this.control.value.trim();
      this.control.control.setValue(value);
    }
  }
}
