import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
  Renderer2, Inject
} from "@angular/core";
import { ConsoleMgmtService } from "./services/utilities/console-mgmt.service";

import { DOCUMENT } from '@angular/common';

import * as moment from "moment";

import { CoolLocalStorage } from "@angular-cool/storage";
import { NavigationEnd, Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { LogoutComponent } from "./modules/persistent/menu/modals/logout/logout.component";
import { BehaviorSubject, Subject, firstValueFrom } from "rxjs";
import { NeedAuthGuardService } from "./services/utilities/need-auth-guard.service";
import { NotificationsService } from "./services/utilities/notifications.service";
import { XrPlatformRestService } from "./services/rest/xr-platform/xr-platform-rest.service";
import { SharedDataService } from "./services/shared-data/shared-data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  //for logging out
  public logoutFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog modal-dialog-centered manage-logout-modal",
    containerClass: "",
    animated: true,
    data: {},
  };

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {

    //check for login via admin_panel_jwt
    let token = this.coolLocalStorage.getItem("admin_panel_jwt");

    if (!token) {
      this.coolLocalStorage.removeItem("admin_panel_user_logged_in");
    }
  }

  title = "glimpse-unified-admin";

  constructor(
    private consoleMgmtService: ConsoleMgmtService,
    private coolLocalStorage: CoolLocalStorage,
    private router: Router,
    private modalService: MDBModalService,
    private _needAuthGuardService: NeedAuthGuardService,
    private _notificationService: NotificationsService,
    private _xrPlatformRestService: XrPlatformRestService,
    private _sharedDataService: SharedDataService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.consoleMgmtService.disableConsoleInProduction();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url.slice(1);
        document.body.className = url.split("/").pop() || "dashboard";

        if (url.indexOf("analytics/readquest") > -1) {
          window.scrollTo(0, 0);
        }

        this._needAuthGuardService.resetCanActivateCalled();
      }
    });

    this._sharedDataService.sessionTimeoutEvent.subscribe((data) => {
      this.manageLogout(data);
    });
  }

  private async manageLogout(data) {

    let initiate = data.initiate;
    let token = data.token;
    let skipExtend = data.skipExtend;
    let clientCode = data.clientCode;
    let user = data.user;
    let userClientCode = data.userClientCode;

    console.log("manageLogout", data);

    if (initiate) {

      console.log("go logout");
      let result = "logout";

      if (!skipExtend) {
        //check to see if this is the current session
        let sessionCheck = sessionStorage.getItem("admin_panel_current_session");

        console.log("sessionCheck in need-auth", sessionCheck);

        if (sessionCheck) {

          this.renderer.addClass(this.document.body, 'modal-open');

          this.logoutFrame = this.modalService.show(
            LogoutComponent,
            this.modalOptions
          );

          let extend = await firstValueFrom(
            this.logoutFrame.content.outgoing
          ) as { status: string };

          console.log("incomingData from logoutFrame", extend)

          if (
            extend.status !== undefined
          ) {

            result = extend.status;

            if (result === "logout") {
              token = null;
            }
          }
        }
      }

      console.log("result in need-auth", result);

      if (result === "extend") {
        let myTimestamp: number = moment().unix();
        this.coolLocalStorage.setItem(
          "admin_panel_login_time_stamp",
          String(myTimestamp)
        );
        return true;
      } else {
        let logout = await this.logout(clientCode, user);
      }

      setTimeout(() => {
        //remove all stored data (just in case)
        let rememberCode = this.coolLocalStorage.getObject(
          "admin_panel_rememberLogin"
        );

        this.coolLocalStorage.clear();

        if (rememberCode) {
          setTimeout(() => {
            this.coolLocalStorage.setItem(
              "admin_panel_stored_code",
              userClientCode
            );

            this.coolLocalStorage.setItem(
              "admin_panel_stored_username",
              user.username
            );

            this.coolLocalStorage.setObject(
              "admin_panel_stored_rememberLogin",
              true
            );
          }, 1);
        }

        this._needAuthGuardService.redirectToLogin(clientCode);
      }, 942);
    } else {
      this._needAuthGuardService.redirectToLogin(clientCode);
    }
  }

  private async logout(clientcode, user) {

    let notification = this._notificationService.savingNotification(
      `Logging out...`
    );
    let messageOut = "You have been logged out.";

    try {
      await this._xrPlatformRestService.logout().toPromise();
      // If logout is successful but we need to perform logout actions
      const logoutActionResult = await this._needAuthGuardService.logoutActions(clientcode, user);
      return logoutActionResult; // This will be the success message from logoutActions
    } catch (err) {
      let errorMsg = err;

      if (err._body !== undefined) {
        errorMsg = JSON.parse(err._body);
      } else if (err.error !== undefined) {
        errorMsg = err.error;
      }

      if (errorMsg.error === "Nobody to logout") {
        // Handle logout actions even if there's "Nobody to logout"
        const logoutActionResult = await this._needAuthGuardService.logoutActions(clientcode, user);
        return logoutActionResult; // Adjust based on what you want to return here
      } else {
        messageOut = errorMsg.error;
        this._notificationService.clearNotification(notification);
        this._notificationService.errorNotification(errorMsg);
      }
    }

    return Promise.resolve({
      "status": "error",
      "message": messageOut
    });
  }
}
