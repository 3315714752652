import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

import { LoginComponent } from "./login/login.component";
import { PersistentModule } from "./../persistent/persistent.module";

import { SharedModulesModule } from "./../shared-modules/shared-modules.module";
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { SetupAccountComponent } from './setup-account/setup-account.component';
import { RequestNewInviteComponent } from './request-new-invite/request-new-invite.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
    declarations: [LoginComponent, PasswordResetComponent, SetupAccountComponent, RequestNewInviteComponent, ForgotPasswordComponent, ResetPasswordComponent],
    imports: [
        CommonModule,
        SharedModulesModule,
        HttpClientModule,
        PersistentModule
    ],
    exports: [LoginComponent]
})
export class AuthenticationModule {}
