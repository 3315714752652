<div class="score-bar-wrapper" [ngClass]="'score-type-' + scoreType">
  <div class="progress score-bar">
    <div
      class="progress-bar"
      role="progressbar"
      [style.width.%]="score"
      aria-valuenow="score"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
