<!--Navbar-->
<mdb-navbar
  SideClass="navbar top-navbar navbar-expand"
  [containerInside]="false"
>
  <!-- side nav button -->

  <!-- /side nav button -->

  <!-- content in the main nav -->
  <div
    class="d-flex align-content-center w-100 justify-content-between align-items-center menu-container"
  >
    <div class="left-side-nav d-flex">
      <a
        (click)="toggleSideNav()"
        class="p-2 button-collapse mobile-button"
        id="mobile-menu-toggle"
        ><mdb-icon
          fas
          icon="bars"
          size="lg"
          class="color-theme-base-dark"
        ></mdb-icon
      ></a>
      <ng-template [ngIf]="pageTitle !== ''">
        <h2
          class="h2 color-theme-base-dark"
          [ngClass]="{ 'animated-ellipsis': loadingAni }"
        >
          {{ pageTitle }}
        </h2>
        <span *ngIf="requiredMsg && !loadingAni" class="action-msg-small">Required*</span>
        <button
          *ngIf="buttonAction"
          class="theme-modal-button btn"
          type="button"
          mdbBtnp
          mdbWavesEffect
          [disabled]="pageLoading"
          (click)="runPageAction()"
        >
          <span class="action-icon" *ngIf="buttonActionType === 'add'"
            ><fa-icon [icon]="faCirclePlus"></fa-icon
          ></span>
          <span class="btn-title">{{ buttonTitle }}</span>
        </button>
        <ng-container
          *ngIf="data.actions !== undefined && data.actions.length > 0"
        >
          <ng-container *ngFor="let action of data.actions">
            <button
              class="theme-modal-button btn"
              type="button"
              mdbBtnp
              mdbWavesEffect
              *ngIf="checkRole(action.roles)"
              [disabled]="pageLoading"
              (click)="runPageAction(action.action)"
            >
              <span class="action-icon"
                ><fa-icon
                  *ngIf="action.type === 'add'"
                  [icon]="faCirclePlus"
                ></fa-icon>
                <fa-icon
                  *ngIf="action.type === 'download'"
                  [icon]="faDownload"
                ></fa-icon>
                <fa-icon
                  *ngIf="action.type === 'uploadMedia'"
                  [icon]="faUpload"
                ></fa-icon>
                <fa-icon
                  *ngIf="action.type === 'upload3DObject'"
                  [icon]="faCube"
                ></fa-icon
              ></span>
              <span class="btn-title">{{ action.title }}</span>
            </button>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
    <div
      *ngIf="isLoggedIn | async"
      class="right-side-nav d-flex align-content-center"
    >
      <span class="navbar-text d-flex align-content-top"
        >Hello: {{ user.username }}
      </span>
      <mdb-navbar-brand class="d-flex mx-3 align-content-top"
        ><a
          class="navbar-brand m-0 p-0"
          [routerLink]="['/client', clientCode]"
          id="client-logo"
        >
          <img class="img-fluid" [src]="logoSrc"
        /></a>
      </mdb-navbar-brand>
      <div class="d-flex flex-row">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item d-flex align-content-top">
            <a
              class="nav-link waves-light d-flex align-content-top"
              mdbWavesEffect
              (click)="logout()"
              id="logout"
              >Logout
              <mdb-icon
                fas
                icon="sign-out-alt"
                class="p-0 d-flex align-content-center"
              ></mdb-icon
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</mdb-navbar>
<!--/.Navbar-->
