import { AgRendererClickableIconComponent } from './../metrics-aia/renderers/ag-renderer-clickable-icon/ag-renderer-clickable-icon.component';
import { CoolLocalStorage } from '@angular-cool/storage';
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SettingsService } from "src/app/services/utilities/settings.service";

import * as moment from "moment";
import { DateTime, IANAZone } from "luxon";
import * as timezoneFixes from "src/assets/timezones/ianaToAbbreviation.json";

@Injectable({
  providedIn: "root",
})
export class MetricsServicesService {
  private restURL: string = "";
  private apbRestURL: string = "";
  private teamID: number;
  private token: string;

  private usernameFlag = "";

  //custom cell renderers
  public frameworkComponents: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private _clientManagementService: ClientManagementService,
    private _settingsService: SettingsService,
    private coolLocalStorage: CoolLocalStorage
  ) {
    this.frameworkComponents = {
      iconRenderer: AgRendererClickableIconComponent,
    }
  }

  public setTeamID(teamID) {
    this.teamID = teamID;

    let storedSettings = this._settingsService.getSettingsFromStorage(teamID);
    this.restURL = storedSettings.metricsURI.unconstrained_default;
    this.apbRestURL = this._clientManagementService.getRESTurl();

    //add trailing slash to restURL if needed
    if (this.restURL[this.restURL.length - 1] !== "/") {
      this.restURL += "/";
    }

    if (
      storedSettings.showMetricsUsername !== undefined &&
      storedSettings.showMetricsUsername.unconstrained_default == true
    ) {
      this.usernameFlag += "/flag";
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public getMetricsColors() {
    let chartColors = [
      "#002738",
      "#04c9d1",
      "#483c46",
      "#be7c4d",
    ];

    return chartColors;
  }

  public getMetricsOverTimeFrame(
    teamID: number,
    timeDenomination,
    startDate,
    endDate,
    tmz,
    apBackend?: boolean
  ): Observable<any> {

    if (apBackend === undefined) apBackend = false;

    let restURL = this.restURL +
      `sessions/sessionsovertime/${teamID}/${timeDenomination}/${startDate}/${endDate}/${tmz}`;

    let httpOptions = {};

    if (apBackend) {
      this.retrieveToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      httpOptions = {
        headers: headers,
      };

      restURL = this.apbRestURL + `/foretell/display/standard/sessions/overtime/${teamID}/${timeDenomination}/${startDate}/${endDate}/${tmz}`;
    }

    return this.http
      .get(restURL, httpOptions)
      .pipe(catchError(this.handleError));
  }

  public getSessions(teamID: number, timePeriod, startDate, endDate, tmz, apBackend?: boolean): Observable<any> {

    if (apBackend === undefined) apBackend = false;

    let restURL = this.restURL +
      `sessions/client/${teamID}/${startDate}/${endDate}/${tmz}`

    let httpOptions = {};

    if (apBackend) {
      this.retrieveToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

      httpOptions = {
        headers: headers,
      };

      restURL = this.apbRestURL + `/foretell/display/standard/sessions/client/${teamID}/${timePeriod}/${startDate}/${endDate}/${tmz}`
    }

    return this.http
      .get(
        restURL,
        httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  public getUserSpeakingBreakdown(session_id, tmz): Observable<any> {
    //parse tmz by /
    let tmzParts = tmz.split("/");

    return this.http
      .get(
        this.restURL +
        `session/${session_id}/users/breakdown/speaking?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`
      )
      .pipe(catchError(this.handleError));
  }

  public getUserGazingBreakdown(teamID, session_id, tmz): Observable<any> {
    //parse tmz by /
    let tmzParts = tmz.split("/");

    return this.http
      .get(
        this.restURL +
        `session/${session_id}/users/breakdown/gazing?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`
      )
      .pipe(catchError(this.handleError));
  }

  public getUserBreakdown(teamID, session_id, tmz): Observable<any> {

    //parse tmz by /
    let tmzParts = tmz.split("/");

    return this.http
      .get(
        this.restURL +
        `session/${session_id}/users/breakdown?tmz_general=${tmzParts[0]}&tmz_specific=${tmzParts[1]}&username_flag=${this.usernameFlag}`
      )
      .pipe(catchError(this.handleError));
  }

  public restfulForetellAPIRequest(
    url: string,
    action: string,
    data,
    options?: any,
    useAPB?: boolean
  ): Observable<any> {
    if (options === undefined) {
      options = {};
    }

    if (useAPB === undefined) useAPB = false;

    if (action == "get") {

      let restURL = this.restURL + url;

      if (useAPB) restURL = this.apbRestURL + "/" + url;

      if (data.queryParams !== undefined) {

        let queryParams = data.queryParams;

        console.log("this.apbRestURL", this.apbRestURL);
        console.log("this.restURL", this.restURL);

        // Create a URL object
        const queryURL = new URL(url, useAPB ? this.apbRestURL : this.restURL);

        // Append query parameters to the URL
        Object.keys(queryParams).forEach(key => queryURL.searchParams.append(encodeURIComponent(key), encodeURIComponent(queryParams[key])));

        restURL = queryURL.href;

      }

      return this.http
        .get(restURL, options)
        .pipe(catchError(this.handleError));
    } else if (action == "post") {
      return this.http
        .post(this.restURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "put") {
      return this.http
        .put(this.restURL + url, data, options)
        .pipe(catchError(this.handleError));
    } else if (action == "delete") {
      return this.http
        .delete(this.restURL + url, options)
        .pipe(catchError(this.handleError));
    } else {
      return null;
    }
  }

  public createColumnDefinitions(columns) {
    let getTimezones = this.resolveTimeZone();

    let columnsOut = columns.map(column => ({
      headerName: column.headerName,
      field: column.field !== undefined ? column.field : null,
      filter: column.filter !== undefined ? column.filter : false,
      floatingFilter: column.filter !== undefined ? column.filter : false,
      filterParams: column.filterParams !== undefined ? column.filterParams : {},
      cellStyle: column.cellStyle !== undefined ? column.cellStyle : {},
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (column.type === "number" || column.type === "percentage" || column.type === "time") {
          return valueA - valueB;
        } else if (column.type === "text") {
          if (valueA.toLowerCase() == valueB.toLowerCase()) return 0;
          return (valueA.toLowerCase() > valueB.toLowerCase()) ? 1 : -1;
        } else if (column.type === "date") {
          if (valueA === null || valueB === null) return 0;
          //convert valueA and valueB to a unix timestamp
          //valueA and valueB are a string in the format Tue, 11 Jun 2024 19:35:09 GMT

          console.log("valueA", valueA);
          console.log("valueB", valueB);

          let unixA = DateTime.fromFormat(valueA.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).toMillis();
          let unixB = DateTime.fromFormat(valueB.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).toMillis();

          console.log("unixA", unixA);
          console.log("unixB", unixB);

          //compare numerical values for sorting
          return unixA - unixB;
        } else if (column.type === "boolean") {
          return valueA - valueB;
        } else {
          return valueA.localeCompare(valueB);
        }
      },
      valueFormatter: (params) => {
        if (column.type === "time") {
          //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
          let durationFormatted = this.convertDurationToTime(params.value);

          return `${durationFormatted}`;
        } else if (column.type === "date") {
          if (params.value === null) return "N/A";
          return this.timeFormatter(params.value, "LLL d yyyy h:mm a");
        } else if (column.type === "percentage") {
          return params.value === null ? "0%" : `${Math.round(params.value * 100)}%`;
        } else if (column.type === "boolean") {
          return params.value === true ? "Yes" : "No";
        } else {
          return params.value;
        }
      },
      cellRenderer: column.cellRenderer !== undefined ? column.cellRenderer : null,
      cellRendererParams: this.processCellRendererParams(column),
      wrapText: column.wrapText !== undefined ? column.wrapText : false,
      autoHeight: column.wrapText !== undefined ? column.wrapText : false,
    }));

    console.log("columnsOut", columnsOut);

    return columnsOut;
  }

  private processCellRendererParams(column) {
    console.log("column in processCellRendererParams", column);
    let cellRendererParams = {};

    switch (column.type) {
      case "icons":
        cellRendererParams = {
          icons: column.icons,
          onClick: (event) => this.iconClicked(event, column)
        }
        break;
    }

    return cellRendererParams;
  }

  private iconClicked(e, column) {
    console.log("icon clicked", e);
    console.log("column in iconClicked", column);
    column.callback(e, column);
  }

  public timeFormatter(time, format = "EEE, dd LLL yyyy h:mm a z") {
    let getTimezones = this.resolveTimeZone();

    // Convert the formatted time to a DateTime object using luxon
    let dt = DateTime.fromFormat(time.replace(getTimezones.tz, getTimezones.tz_iana), 'EEE, dd LLL yyyy HH:mm:ss z', { setZone: true }).setZone(getTimezones.tz_iana).toFormat(format);

    // Replace the IANA timezone with the original timezone
    dt = dt.replace(getTimezones.tz_iana, getTimezones.tz);

    return dt;
  }

  public convertDurationToTime(duration) {
    //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
    let hours = Math.floor(duration / 3600).toString().padStart(2, '0');
    let minutes = Math.round((duration % 3600) / 60).toString().padStart(2, '0');
    let seconds = Math.round(duration % 60).toString().padStart(2, '0');

    let durationFormatted = `${hours}:${minutes}:${seconds}`;

    return durationFormatted;
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }

  public resolveTimeZone() {
    let tz_iana = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tz = moment.tz(tz_iana).format("z");

    //if the string includes a negative number in the form -03 or -02, check for a timezone fix
    if (tz.includes("-")) {
      let fix = timezoneFixes[tz_iana];

      if (fix !== undefined) {
        tz = fix;
      } else {
        //fall back to UTC from Luxon
        tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
      }
    } else if (tz.includes("+")) {
      //fall back to UTC from Luxon
      //@todo: update timezone fixes to include plus returns
      tz = DateTime.now().setZone(tz_iana).toFormat("ZZZZ");
    }

    return {
      tz_iana: tz_iana,
      tz: tz,
    }
  }

  public retrieveQueryParams(params) {
    const legacyState = params.get('legacy_state') !== undefined ? params.get('legacy_state') : "false";

    const is_teacher = params.get('is_teacher') === null ? true : params.get('is_teacher') === "true" ? true : false;
    const userType = is_teacher === true ? "teacher" : "student";
    const start = params.get('start') !== null ? moment(params.get('start')).format("YYYY-MM-DD") : moment().subtract(7, 'days').format("YYYY-MM-DD");
    const end = params.get('end') !== null ? moment(params.get('end')).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const table_type = params.get('table_type') !== null ? params.get('table_type') : "skills";

    return {
      legacyState: legacyState,
      userType: userType,
      is_teacher: is_teacher,
      start: start,
      end: end,
      table_type: table_type
    }
  }

}
