import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { pairwise } from "rxjs/operators";
import { startWith } from "rxjs/operators";

import {
  faCirclePlus,
  faImages,
  faPlusSquare,
  faMinus,
  faTimes,
  faEyeSlash,
  faEye,
  faCopy,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";

import { EventServicesService } from "src/app/modules/event-management/services/event-services.service";

@Component({
  selector: 'app-manage-event-part-experiences',
  templateUrl: './manage-event-part-experiences.component.html',
  styleUrls: ['./manage-event-part-experiences.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ManageEventPartExperiencesComponent),
      multi: true
    }
  ]
})
export class ManageEventPartExperiencesComponent {
  @Input() experiences: any[];
  @Input() experiencesFormArray: UntypedFormArray;
  @Input() experienceFieldsGroups: any[];
  @Input() experiencesSelectCurrent: { value: number; label: string }[];
  @Input() experiencesSelect: any[];
  @Input() experiencesSelectBase: any[];
  @Input() startingExperienceSelects: any[];
  @Input() attendeePropID: number;
  @Input() starting_experience_id: any;
  @Input() manageEventForm: UntypedFormGroup;
  @Input() targetEvent: any;
  @Input() labels: any;
  @Input() is_hub: boolean;
  @Input() is_aia: boolean;
  @Input() isClean: boolean;
  @Input() teamProps: any;
  @Input() teamPropOptions: any;
  @Input() preSelected: any;
  @Input() currentAttendeeValue: number;
  @Input() attendeeSelect: any;

  @Output() dataChanges = new EventEmitter();

  //fa icons
  public faCirclePlus = faCirclePlus;
  public faImages = faImages;
  public faMinus = faMinus;
  public faPlusSquare = faPlusSquare;
  public faMinusSquare = faMinusSquare;
  public faTimes = faTimes;
  public faEyeSlash = faEyeSlash;
  public faEye = faEye;
  public faCopy = faCopy;

  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };
  private isValueChangeAllowed = true;

  constructor(private eventService: EventServicesService) { }

  public ngOnInit() {
    console.log("this.experiencesSelectCurrent in ngOnInit", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

    console.log("this.starting_experience_id.value in ngOnInit", this.starting_experience_id.value);

    console.log("is_aia in ngOnInit", this.is_aia);

    if (this.targetEvent !== undefined && this.targetEvent.starting_experience !== null) {
      this.setupExperiences();
    } else if (this.targetEvent !== undefined) {
      this.addExperienceFields();
    }
  }

  public setupExperiences() {
    if (this.targetEvent.starting_experience !== null) {
      //first remove starting experience from experience selects
      let updatedSelectsInitial = this.removeExperienceFromSelects(
        this.targetEvent.starting_experience.id,
        -1,
        this.experiencesSelectCurrent,
        this.experiencesSelect,
        "staring-experience"
      );

      this.experiencesSelectCurrent = updatedSelectsInitial.currentSelects;

      console.log("this.experiencesSelectCurrent in setupExperiences", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

      this.experiencesSelect = updatedSelectsInitial.selects;
    }

    if (this.targetEvent.experiences.length) {
      //remove starting experience targetEvent.experiences
      this.targetEvent.experiences = this.targetEvent.experiences.filter(
        (exp) => {
          return exp.id !== this.targetEvent.starting_experience.id;
        }
      );

      //for updating, clean up the experience selects for existing experiences
      this.targetEvent.experiences.forEach((exp_rep, index) => {
        let updatedSelects = this.removeExperienceFromSelects(
          exp_rep.id,
          index,
          this.experiencesSelectCurrent,
          this.experiencesSelect,
          "additional-experiences"
        );

        this.experiencesSelectCurrent = updatedSelects.currentSelects;

        console.log("this.experiencesSelectCurrent in setupExperiences", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

        this.experiencesSelect = updatedSelects.selects;
      });

      let index = 0;
      this.targetEvent.experiences.forEach((exp_rep) => {
        this.addExperienceFields(index, exp_rep.id);
        let currentExperience = {
          value: exp_rep.id,
          label: `${exp_rep.label} (Capacity: ${exp_rep.max_attendees})`,
        };

        this.experiencesSelect = this.experiencesSelectSetupEach(
          index,
          exp_rep.id,
          currentExperience,
          this.experiencesSelectCurrent,
          this.experiencesSelect
        );

        index++;
      });

    } else {
      this.addExperienceFields();
    }
  }

  public addExperienceFields(index?, experience_id?) {

    if (index === undefined) index = 0;

    let starting_experience =
      this.targetEvent !== undefined &&
        this.targetEvent.starting_experience !== undefined &&
        this.targetEvent.starting_experience !== null &&
        experience_id === this.targetEvent.starting_experience.id
        ? this.targetEvent.starting_experience.id
        : 0;

    this.experiencesFormArray.push(
      new UntypedFormGroup({
        experience_id: new UntypedFormControl(
          {
            value: experience_id === undefined ? "" : experience_id,
            disabled: this.starting_experience_id.value === null,
          },
          Validators.required
        ),
      })
    );

    this.experiencesSelect[index] = JSON.parse(
      JSON.stringify(this.experiencesSelectCurrent)
    );

    let start_with =
      experience_id !== undefined
        ? {
          experience_id: experience_id,
          starting_experience_id: starting_experience,
        }
        : null;

    console.log("this.experiencesFormArray in addExperienceFields", this.experiencesFormArray);

    console.log("index", index);

    this.experiencesFormArray.controls[index].valueChanges
      .pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]: [any, any]) => {

        console.log("prev in addExperienceFields experiencesFormArray.valueChanges", prev);
        console.log("next in addExperienceFields experiencesFormArray.valueChanges", next);

        //this is a starting experience change
        if (prev !== null && prev.experience_id === next.experience_id) return;

        //reset form protection
        if (next.experience_id !== undefined && next.experience_id === null)
          return;

        //also indicates a starting experience change
        if (next.experience_id === "") return;

        //this seems to occasionally happen where the select does not populate
        if (prev === null && next !== null && next.experience_id === undefined)
          return;

        this.isClean = false;
        this.dataChanges.emit({
          isClean: this.isClean,
        });

        let updatedSelects = this.removeExperienceFromSelects(
          next.experience_id,
          index,
          this.experiencesSelectCurrent,
          this.experiencesSelect,
          "additional-experiences"
        );

        this.experiencesSelectCurrent = updatedSelects.currentSelects;

        console.log("this.experiencesSelectCurrent in addExperienceFields #1", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

        this.experiencesSelect = updatedSelects.selects;

        if (
          prev !== null &&
          prev.experience_id !== undefined &&
          prev.experience_id !== ""
        ) {
          let updatedSelects = this.restoreExperienceSelect(
            prev.experience_id,
            "experience_id",
            this.experiencesSelectCurrent,
            this.experiencesSelect,
            this.experiencesSelectBase,
            this.experiencesFormArray
          );

          this.experiencesSelectCurrent = updatedSelects.currentSelects;

          console.log("this.experiencesSelectCurrent in addExperienceFields #2", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

          this.experiencesSelect = updatedSelects.selects;
        }

        //update number of attendees if this is the starting experience
        if (
          this.attendeePropID > 0 &&
          this.starting_experience_id.value === next.experience_id
        ) {
          let newExperience = this.eventService.getExperienceByID(next.experience_id, this.experiences);
          let max = newExperience[0].experience.max_attendees;

          let evaluateProps = this.eventService.evaluateProps(this.teamProps, this.attendeePropID, this.teamPropOptions, this.targetEvent, this.preSelected, this.currentAttendeeValue, this.manageEventForm);

          this.attendeeSelect = evaluateProps.attendeeSelect;
          this.attendeePropID = evaluateProps.attendeePropID;
          this.teamPropOptions = evaluateProps.teamPropOptions;
          this.preSelected = evaluateProps.preSelected;
          this.currentAttendeeValue = evaluateProps.currentAttendeeValue;
          this.manageEventForm = evaluateProps.manageEventForm;
          this.manageEventForm.controls["number_of_attendees_prop"].enable();
        }

        this.experiencesFormArray.controls.forEach((control, control_index) => {

          console.log("control_index in addExperienceFields", control_index);

          //get current control value
          let next = control.value;

          if (next === null) return;

          //make sure form array with current experience id has that experience in the selects
          let currentExperience = this.eventService.getExperienceByID(next.experience_id, this.experiences);

          let currentExperienceSelect = {
            value: next.experience_id,
            label: `${currentExperience[0].experience.label} (Capacity: ${currentExperience[0].experience.max_attendees})`,
          };

          //see if currentExperienceSelect is already in this.experiencesSelect[index]
          let found = this.experiencesSelect[control_index].find((select) => {
            return select.value === currentExperienceSelect.value;
          });

          //if it's not found, add it
          if (found === undefined) {
            this.experiencesSelect[control_index].unshift(currentExperienceSelect);
          }
        });

        // Emit the updated value to the parent form
        //this.onChange(this.experiencesFormArray.value);
      });
  }

  public addAllExperienceFields() {

    //remove any empty experiences
    this.experiencesFormArray.controls.forEach((control, index) => {
      if (control.value.experience_id === "") {
        this.removeExperienceField(index);
      }
    });

    let index = this.experiencesFormArray.controls.length;

    //for all experiences that are not already added, add them
    this.experiencesSelectBase.forEach((select) => {
      let found = this.experiencesFormArray.controls.find((control) => {
        return control.value.experience_id === select.value;
      });

      if (select.value === this.starting_experience_id.value) return

      console.log("select in addAllExperienceFields", select);

      if (found === undefined) {
        this.addExperienceFields(index);
        //trigger value changes on the new experience field
        this.experiencesFormArray.controls[index].get("experience_id").setValue(select.value);
        index++;
      }
    });

    //again remove any empty experiences
    //@todo: figure out why this extra empty field is added in the first place
    this.experiencesFormArray.controls.forEach((control, index) => {
      if (control.value.experience_id === "") {
        this.removeExperienceField(index);
      }
    });

    console.log("emitting from addAllExperienceFields");
  }

  public removeExperienceFromSelects(
    experience_id,
    index,
    currentSelects,
    selects,
    from
  ) {
    let currentExperience = {
      value: null,
      label: null,
    };
    currentSelects.forEach((option, option_index) => {
      if (option.value === experience_id) {
        currentExperience = option;
      }
    });

    //this means we're coming from a starting experience selection
    if (from === "starting-experience") {
      currentExperience = experience_id;
      experience_id = currentExperience.value;
    }

    currentSelects = currentSelects.filter((option) => {
      return option.value !== experience_id;
    });

    selects = this.experiencesSelectSetupEach(
      index,
      experience_id,
      currentExperience,
      currentSelects,
      selects
    );

    //also remove from starting experience select if this is not the starting experience
    if (this.starting_experience_id.value !== experience_id) {
      this.startingExperienceSelects.forEach((option, select_index) => {
        if (option.value === experience_id) {
          this.startingExperienceSelects.splice(select_index, 1);
        }
      });
    }

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  public removeExperienceField(index) {

    let currentValue = this.experiencesFormArray.controls[index].value;

    console.log("currentValue in removeExperienceField", currentValue);

    this.experiencesSelect = this.experiencesSelect.filter(
      (select, select_index) => {
        return select_index !== index;
      }
    );

    console.log("this.experiencesSelect in removeExperienceField", this.experiencesSelect);

    this.experiencesFormArray.removeAt(index);

    if (currentValue.experience_id !== "") {
      let updatedSelects = this.restoreExperienceSelect(
        currentValue.experience_id,
        "experience_id",
        this.experiencesSelectCurrent,
        this.experiencesSelect,
        this.experiencesSelectBase,
        this.experiencesFormArray
      );

      this.experiencesSelectCurrent = updatedSelects.currentSelects;

      console.log("this.experiencesSelectCurrent in removeExperienceField", JSON.parse(JSON.stringify(this.experiencesSelectCurrent)));

      this.experiencesSelect = updatedSelects.selects;
    }

    this.isClean = false;
  }

  public restoreExperienceSelect(
    currentValue,
    targetKey,
    currentSelects,
    selects,
    baseSelects,
    currentFormArray
  ) {

    let restoredValue = this.experiencesSelectGetValue(
      currentValue,
      baseSelects
    );

    console.log("currentSelects in restoreExperienceSelect", JSON.parse(JSON.stringify(currentSelects)));

    //this means we are dealing with moving from a hub back to a regular experience, and hubs are not in the baseSelects
    if (restoredValue.length === 0)
      return {
        currentSelects: currentSelects,
        selects: selects,
      };

    currentSelects.unshift(restoredValue[0]);
    currentSelects.sort((a, b) => a.label.localeCompare(b.label));

    let selectedArray = JSON.parse(JSON.stringify(currentSelects));

    console.log("currentFormArray in restoreExperienceSelect", JSON.parse(JSON.stringify(currentSelects)));

    //make sure each experience select has the restored experience + the currently selected experience
    currentFormArray.controls.forEach((control, control_index) => {

      //first reset selects
      selectedArray = JSON.parse(JSON.stringify(currentSelects));

      if (control.value[targetKey] === "") {
        selects[control_index] = selectedArray;
        return;
      }

      let currentExperience = this.experiencesSelectGetValue(
        control.value[targetKey],
        baseSelects
      );

      selectedArray.unshift(currentExperience[0]);
      selectedArray.sort((a, b) => a.label.localeCompare(b.label));
      selects[control_index] = selectedArray;
    });

    if (this.starting_experience_id.value !== currentValue) {
      this.startingExperienceSelects.unshift(restoredValue[0]);
      this.startingExperienceSelects.sort((a, b) => a.label.localeCompare(b.label));
    }

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  private experiencesSelectGetValue(currentValue, baseSelects) {
    return baseSelects.filter((option) => {
      return option.value === currentValue;
    });
  }

  private experiencesSelectSetupEach(
    index,
    experience_id,
    currentExperience,
    currentSelects,
    selects
  ): any {
    if (index === -1) return selects;

    selects.forEach((select, select_index) => {

      if (select_index !== index) {
        selects[select_index] = select.filter(option => option.value !== experience_id);
      }

      let selectedArray = JSON.parse(JSON.stringify(currentSelects));

      if (currentExperience !== null && currentExperience.value !== null)
        selectedArray.unshift(currentExperience);

      selects[index] = selectedArray;
    });

    return selects;
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {

    console.log("value in writeValue", value);

    this.isValueChangeAllowed = false;
    if (value) {
      this.experiencesFormArray.setValue(value);
    }
    this.isValueChangeAllowed = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.experiencesFormArray.valueChanges.subscribe((val) => {

      console.log("val in registerOnChange", val);

      // if (this.isValueChangeAllowed) {
      //   this.onChange(val);
      // }
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.experiencesFormArray.disable();
    } else {
      this.experiencesFormArray.enable();
    }
  }

}
