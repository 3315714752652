<div class="login-wrapper">
  <mdb-card>
    <mdb-card-header class="theme-base-medium-dark white-text text-center py-4">
      <h5><strong>Sign in</strong></h5>
    </mdb-card-header>
    <mdb-card-body>
      <div
        *ngIf="errorMsg !== ''"
        class="alert alert-danger"
        role="alert"
        [innerHTML]="errorMsg"
      ></div>
      <div
        *ngIf="statusMsg !== ''"
        class="alert alert-info"
        role="alert"
        [innerHTML]="statusMsg"
      ></div>
      <form
        [formGroup]="loginForm"
        *ngIf="!formLoading"
        (ngSubmit)="processLogin()"
      >
        <div class="md-form">
          <label for="clientcode">Client Code</label>
          <input
            #focusElem
            autofocus
            mdbInput
            type="text"
            class="form-control"
            formControlName="clientcode"
            name="clientcode"
            id="clientcode"
            required
            mdbValidate
          />
          <mdb-error
            *ngIf="
              loginForm.controls.clientcode.invalid &&
              (loginForm.controls.clientcode.dirty ||
                loginForm.controls.clientcode.touched)
            "
          >
            Please enter a client code
          </mdb-error>
        </div>
        <div class="md-form">
          <label for="username">Username</label>
          <input
            mdbInput
            type="text"
            class="form-control"
            formControlName="username"
            name="username"
            id="username"
            required
            mdbValidate
          />
          <mdb-error
            *ngIf="
              loginForm.controls.username.invalid &&
              (loginForm.controls.username.dirty ||
                loginForm.controls.username.touched)
            "
          >
            Please enter a username
          </mdb-error>
        </div>

        <div class="md-form">
          <label for="password">Password</label>
          <input
            mdbInput
            type="password"
            class="form-control"
            formControlName="password"
            name="password"
            id="password"
            required
            mdbValidate
          />
          <mdb-error
            *ngIf="
              loginForm.controls.password.invalid &&
              (loginForm.controls.password.dirty ||
                loginForm.controls.password.touched)
            "
          >
            Please enter a password
          </mdb-error>
        </div>

        <!-- <a
            *ngIf="teamID !== 0"
            id="admin-reset-password"
            [routerLink]="['/client', teamID, 'password-reset']"
            >Forgot password?</a
          >
          <a
            *ngIf="teamID === 0"
            [routerLink]="['/password-reset']"
            id="reset-password"
            >Forgot password?</a
          > -->
        <div class="buttons-wrapper">
          <div class="md-form">
            <mdb-checkbox formControlName="rememberLogin">
              Remember client code and username
            </mdb-checkbox>
          </div>
          <button
            mdbBtn
            type="submit"
            mdbWavesEffect
            class="theme-modal-button btn"
            id="sign-in"
          >
            Sign in
          </button>
        </div>
      </form>
    </mdb-card-body>
  </mdb-card>
  <p class="text-center mt-2">
    Forgot Your Password?
    <a [routerLink]="['/client', clientcode, 'forgot-password']">
      Click here.
    </a>
  </p>
</div>
